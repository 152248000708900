input, select, textarea{
    font-family: var(--font_body);
    color: inherit;
}

.kui-textfield{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .25em;
    &.is--error{
        .kui-textfield-label__text{
          color: $danger;
          font-weight: bold;
        }
        .kui-textfield__field{
          background: rgba($color: $danger, $alpha: 0.05);
          border: 1px solid $danger;
        }
    }
    label.kui-textfield-label{
        position: relative;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: .5em;
        align-items: center;
        strong{
            font-weight: 600;
            font-size: .9em;
        }
    }
    &.is--unmodifiable{
        pointer-events: none;
        opacity: .8;
    }
    
    &__optionnal{
        padding: .3em .6em;
        font-size: .8em;
        background: var(--background_tint);
        font-style: italic;
        display: flex;
        align-items: center;
        border-radius: .8em;
    }
    &__field{
        position: relative;
        width: 100%;
        background: var(--background_tint);
        color: inherit;
        padding: .6em .8em;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: .1em;
        font-size: .9em;
        border-radius: .8em;
        textarea, input{
            position: relative;
            width: 100%;
            background: transparent;
            outline: none;
            color: inherit;
            border: none;
            min-height: 1.4em;
        }
        textarea{
            resize: vertical;
            max-height: 25em;
            min-height: 8em;
        }
    }
    &__btn{
        position: relative;
        min-width: 1.4em;
        height: 1.4em;
        @extend %flex-center;
        cursor: pointer;
        i{
            transition: .3s ease;
        }
    }
    .kui-textfield-message{
        position: relative;
        width: 100%;
    }
}

.kui-selectfield{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .25em;
    &.is--error{
        .kui-selectfield-label__text{
          color: $danger;
          font-weight: bold;
        }
        .kui-selectfield__field{
          background: rgba($color: $danger, $alpha: 0.05);
          border: 1px solid $danger;
        }
    }
    .kui-selectfield-message{
        position: relative;
        width: 100%;
    }
}
.kui-selectfield-label{
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: .5em;
    align-items: center;
    strong{
        font-weight: 600;
        font-size: .9em;
    }
    &__optionnal{
        padding: .3em .6em;
        font-size: .8em;
        background: var(--background_tint);
        font-style: italic;
        display: flex;
        align-items: center;
        border-radius: .8em;
    }
}
.kui-selectfield-field{
    position: relative;
    width: 100%;
    &__container{
        position: relative;
        width: 100%;
        background: var(--background_tint);
        color: inherit;
        padding: .6em .8em;
        gap: .1em;
        font-size: .9em;
        border-radius: .8em;
        min-height: 2.5em;
        display: flex;
        align-items: center;
    }
    &__grid{
        position: relative;
        width: 100%;
        display: flex;
        gap: .5em;
        flex-wrap: wrap;
    }
}
.kui-selectfield-field-item{
    position: relative;
    padding: .5em .6em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: .5em;
    font-size: .9em;
    border-radius: .4em;
    background: var(--background);
}
.kui-selectfield-field-options{
    position: absolute;
    transform: translateY(.1em);
    width: 100%;
    // max-width: 30em;
    max-height: 20em;
    overflow-y: auto;
    padding: .5em;
    border-radius: .8em;
    background: var(--background_tint);
    // border: .02em solid var(--color_rgba1);
    display: none;
    flex-direction: column;
    row-gap: .5em;
    z-index: 9;
    &.is--active{
        display: flex;
    }
    &__search{
        position: relative;
        width: 100%;
        input{
            position: relative;
            width: 100%;
            background: var(--background);
            padding: 0.6em 0.8em;
            min-height: 1.4em;
            color: var(--color);
            outline: none;
            border: none;
            border-radius: .8em;
        }
    }
    &__list{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: .4em;
    }
}

.kui-selectfield-field-option{
    position: relative;
    width: 100%;
    padding: .4em .8em;
    min-height: 1.4em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--background);
    border-radius: .6em;
    transition: .3s ease;
    font-size: .9em;
    &.is--active{
      position: relative;
      background: var(--primary_rgba);
      .kui-selectfield-field-option-text i{
        color: var(--primary);
      }
    }
}
.kui-selectfield-field-option-text{
    position: relative;
    width: calc(100% - 3em);
    display: flex;
    align-items: center;
    column-gap: .3em;
    i{
      font-size: 1em;
    }
   
}

.kui-selectfield-field-option-delete{
    position: relative;
    width: 2em;
    height: 2em;
    @extend %flex-center;
    color: $danger;
    background: rgba($color: $danger, $alpha: 0.05);
    border-radius: .6em;
    i{
      font-size: 1em;
    }
}
  
.kui-editor{
    position: relative;
    width: 100%;
    min-height: 40svh;
    color: inherit;
    margin-bottom: 5svh;
    font-family: var(--font_body);
}

.kui-mulit-file{
    position: relative;
    width: 100%;
    min-height: 20em;
    @extend %flex-center;
    background: var(--background_tint);
    border-radius: .8em;
    padding: .8em;
    &__grid{
        display: flex;
        width: 100%;
        justify-content: center;
        gap: .5em;
        flex-wrap: wrap;
    }
    i{
        color: inherit;
    }
    input{
        display: none;
    }
    label{
        cursor: pointer;
        font-family: var(--font_body);
    }

    .kui-mulit-file-btn{
        position: relative;
        width: 100%;
        height: 12em;
        @extend %flex-center;
        color: var(--color);
        text-align: center;
        flex-direction: column;
        svg{
            color: var(--primary);
        }
    }
    .kui-mulit-file-preview{
      position: relative;
      width: 12em;
      height: 12em;
      background: var(--background);
      border-radius: .8em;
      overflow: hidden;
      @extend %flex-center;
      img, video{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      button{
        position: absolute;
        bottom: .8em;
        right: .8em;
        background: $danger;
        width: 2.7em;
        height: 2.7em;
        @extend %flex-center;
        border-radius: .4em;
        color: #fff;
        svg{
          color: #fff;
          width: 1.7em;
          height: 1.7em;
        }
        z-index: 3;
      }
    }
    label.kui-mulit-file-preview_btn{
        width: 12em;
        height: 12em;
        background: var(--background);
        @extend %flex-center;
        color: var(--color);
        flex-direction: column;
        padding: .8em;
        text-align: center;
        svg{
          color: var(--primary);
        }
        border-radius: .8em;
        transition: 0.3s ease;
        &:hover{
          background: var(--background_tint);
        }
      }
}

.kui-file{
    position: relative;
    width: 100%;
    min-height: 20em;
    @extend %flex-center;
    background: var(--background_tint);
    border-radius: .8em;
    padding: .8em; 
    i{
        color: inherit;
    }
    input{
        display: none;
    }
    label{
        cursor: pointer;
        font-family: var(--font_body);
    }
    &__container{
        position: relative;
        width: 100%;
        height: 30em;
        border-radius: .6em;
        overflow: hidden;
        @extend %flex-center;
        flex-direction: column;
        row-gap: .8em;
        span{
            font-size: 1em;
            font-family: var(--font_title);
        }
        i{
            color: var(--primary);
            font-size: 2em;
        }
        img, video{
            position: relative;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    &__btn{
        position: absolute;
        bottom: 1em;
        right: 1em;
        background: $danger;
        width: 2.7em;
        height: 2.7em;
        @extend %flex-center;
        border-radius: .4em;
        color: #fff;
        svg{
          color: #fff;
          width: 1.7em;
          height: 1.7em;
        }
        z-index: 3;
    }
}

.kui-filefield-lil{
    position: relative;
    width: 100%;
    background: var(--background_tint);
    border-radius: .8em;
    padding: 1em .8em; 
    input{
        display: none;
    }
    &:hover{
        background: var(--primary_rgba);
    }
    label{
        cursor: pointer;
    }
    &__container{
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        gap: .8em;
        justify-content: space-between;
    }
    &__icon{
        position: relative;
        width: 2.5em;
        height: 2.5em;
        @extend %flex-center;
        i{
            font-size: 1.3em;
        }
    }
    &__info{
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: .1em;
    }
}