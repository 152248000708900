.kui-auth-layout{
    position: fixed;
    width: 100%;
    height: 100svh;
    top: 0;
    left: 0;
    display: flex;
    font-family: var(--font_body);
    &__image{
        position: relative;
        width: 100%;
        height: 100svh;
        pointer-events: none;
        overflow: hidden;
        img{
            @extend %image-center;
        }
    }
    &__container{
        position: relative;
        width: 100%;
        max-width: 29em;
        height: 100svh;
        background: var(--background);
        z-index: 9;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 3em 2em;
        display: flex;
        flex-direction: column;
        row-gap: 2em;
    }
    &__back{
        position: relative;
        width: 100%;
        button{
            padding: .4em .6em;
            background: var(--background_tint);
            border-radius: .3em;
            display: flex;
            align-items: center;
            gap: .4em;
            font-size: .98em;
            transition: .3s ease;
            &:hover{
                background: var(--background_rgba_dark2);

            }
        }
    }
    &__header{
        position: relative;
        display: flex;
        flex-direction: column;
        row-gap: 1em;
        svg{
            width: 4em;
            height: auto;
            opacity: .7;
            path{
                fill: currentColor;
            }
        }
        h1{
            font-size: 3em;
            font-family: var(--font_title);
        }
        span{
            opacity: .6;
        }
    }
    &__main{
        position: relative;
        width: 100%;
        margin-top: 3em;
    }
}

.kui-auth-form-field,
.kui-auth-form-action,
.kui-auth-form-info{
    position: relative;
    width: 100%;
}
.kui-auth-form-info{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: .9em;
    // opacity: .9;
}