.kui-table{
    position: relative;
    width: 100%;
    overflow-x: auto;
    overflow-y: visible;
    padding-bottom: 1em;
    &__container{
        position: relative;
        width: auto;
        min-width: 100%;
    }
}

.kui-table-row{
    position: relative;
    width: auto;
    min-width: 100%;
    display: flex;
    border-bottom: .02em solid var(--color_rgba);
    &.is--header{
        strong{
            font-family: var(--font_title);
            font-size: 1.1em;
        }
        // text-transform: uppercase;
    }
}

.kui-table-column{
    // width: auto;
    min-height: 2.7em;
    position: relative;
    display: flex;
    align-items: center;
    border: .1em solid var(--color_rgba);
    padding: 0 .6em;
    &.is--auto{
        width: 100%;
    }
    &.is--small{
        width: 3em;
        min-width: 2.7em;
        @extend %flex-center;
        padding: 0;
    }
    &.is--mid{
        width: 18em;
        min-width: 18em;
    }
    &.is--large{
        width: 40em;
        min-width: 40em;
    }
}

.kui-table-options{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-items: center;
    gap: .2em;
    &__btn{
        position: relative;
        width: 2.5em;
        height: 2.5em;
        @extend %flex-center;
        border-radius: .8em;
        transition: .3s ease;
        &:hover{
            background: var(--color_rgba);
        }
    }
    &__list{
        position: absolute;
        width: auto;
        padding: .5em .8em;
        background: var(--background);
        border-radius: .8em;
        display: none;
        gap: .3em;
        right: 102%;
        top: 50%;
        transform: translateY(-50%);
        border: .1em solid  var(--color_rgba1);
        z-index: 3;
        &.is--open{
            display: flex;
        }
    }
    
}

.kui-table-option,
.kui-table-options__item{
    position: relative;
    padding: .4em .6em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    gap: .5em;
    font-size: .9em;
    border-radius: .8em;
    font-weight: 600;
    transition: .3s ease;
    span{
        white-space: nowrap;
    }
    &:hover{
        background: var(--color_rgba);
    }

    &.is--danger{
        border: .02em solid $danger;
        color: var(--text-color);
        background: rgba($color: $danger, $alpha: 0.1);
        &:hover{
            background: rgba($color: $danger, $alpha: 0.3);
            color: $danger;
        }
    }
    &.is--success{
        border: .02em solid $success;
        color: var(--text-color);
        background: rgba($color: $success, $alpha: 0.1);
        &:hover{
            background: rgba($color: $success, $alpha: 0.3);
            color: $success;
        }
    }
    &.is--warning{
        border: .02em solid $warning;
        background: rgba($color: $warning, $alpha: 0.1);
        &:hover{
            background: rgba($color: $warning, $alpha: 0.3);
            color: $warning;
        }
    }
}


.kui-table-item{
    position: relative;
    width: 100%;
    display: flex;
    gap: .4em;
    padding: .2em 0;
    align-items: center;
    &__avatar{
        position: relative;
        width: 2.9em;
        min-width: 2.9em;
        height: 2.9em;
        background-color: var(--background_tint);
        overflow: hidden;
        @extend %flex-center;
        &.as--circle{
            border-radius: 50%;
        }
        &.as--square{
            border-radius: .4em;
        }
        img{
            @extend %image-center
        }
    }
    &__container{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        font-size: .9em;
        strong{
            font-family: var(--background_tint);
        }
        span{
            opacity: .7;
        }
    }
}
