$icomoon-font-family: "picha-icon" !default;
$icomoon-font-path: "fonts" !default;

$pi-hour-glass: unquote('"\\e979"');
$pi-spinner: unquote('"\\e97a"');
$pi-spinner2: unquote('"\\e97b"');
$pi-spinner3: unquote('"\\e97c"');
$pi-spinner4: unquote('"\\e97d"');
$pi-spinner5: unquote('"\\e97e"');
$pi-spinner6: unquote('"\\e97f"');
$pi-spinner7: unquote('"\\e980"');
$pi-spinner8: unquote('"\\e981"');
$pi-spinner9: unquote('"\\e982"');
$pi-spinner10: unquote('"\\e983"');
$pi-add-circle-line: unquote('"\\e900"');
$pi-add-line: unquote('"\\e901"');
$pi-alert-diamond-line: unquote('"\\e902"');
$pi-alert-line: unquote('"\\e903"');
$pi-alert-octagon-line: unquote('"\\e904"');
$pi-broom-line: unquote('"\\e905"');
$pi-brush-3-line: unquote('"\\e906"');
$pi-brush-line: unquote('"\\e907"');
$pi-bubble-line: unquote('"\\e908"');
$pi-certificate-line: unquote('"\\e909"');
$pi-check-2-line: unquote('"\\e90a"');
$pi-check-circle-line: unquote('"\\e90b"');
$pi-check-line: unquote('"\\e90c"');
$pi-checkbox-line: unquote('"\\e90d"');
$pi-checks-line: unquote('"\\e90e"');
$pi-choice-line: unquote('"\\e90f"');
$pi-classify-2-line: unquote('"\\e910"');
$pi-classify-3-line: unquote('"\\e911"');
$pi-classify-add-2-line: unquote('"\\e912"');
$pi-classify-add-line: unquote('"\\e913"');
$pi-classify-line: unquote('"\\e914"');
$pi-close-circle-line: unquote('"\\e915"');
$pi-close-line: unquote('"\\e916"');
$pi-cross-line: unquote('"\\e917"');
$pi-crutch-line: unquote('"\\e918"');
$pi-cube-line: unquote('"\\e919"');
$pi-delete-2-line: unquote('"\\e91a"');
$pi-delete-3-line: unquote('"\\e91b"');
$pi-delete-back-line: unquote('"\\e91c"');
$pi-delete-line: unquote('"\\e91d"');
$pi-direction-dot-line: unquote('"\\e91e"');
$pi-door-line: unquote('"\\e91f"');
$pi-dot-grid-line: unquote('"\\e920"');
$pi-dots-line: unquote('"\\e921"');
$pi-dots-Vertical-line: unquote('"\\e922"');
$pi-enter-door-line: unquote('"\\e923"');
$pi-entrance-line: unquote('"\\e924"');
$pi-exit-door-line: unquote('"\\e925"');
$pi-exit-line: unquote('"\\e926"');
$pi-eye-close-line: unquote('"\\e927"');
$pi-eye-line: unquote('"\\e928"');
$pi-faceid-line: unquote('"\\e929"');
$pi-filter-2-line: unquote('"\\e92a"');
$pi-fingerprint-2-line: unquote('"\\e92b"');
$pi-fingerprint-line: unquote('"\\e92c"');
$pi-fire-line: unquote('"\\e92d"');
$pi-flame-line: unquote('"\\e92e"');
$pi-forbid-circle-line: unquote('"\\e92f"');
$pi-gesture-unlock-line: unquote('"\\e930"');
$pi-ghost-line: unquote('"\\e931"');
$pi-grid-2-line: unquote('"\\e932"');
$pi-grid-line: unquote('"\\e933"');
$pi-heartbeat-line: unquote('"\\e934"');
$pi-history-2-line: unquote('"\\e935"');
$pi-history-anticlockwise-line: unquote('"\\e936"');
$pi-history-line: unquote('"\\e937"');
$pi-information-line: unquote('"\\e938"');
$pi-key-1-line: unquote('"\\e939"');
$pi-key-2-line: unquote('"\\e93a"');
$pi-keyhole-line: unquote('"\\e93b"');
$pi-lock-line: unquote('"\\e93c"');
$pi-mind-map-line: unquote('"\\e93d"');
$pi-minimize-line: unquote('"\\e93e"');
$pi-minus-circle-line: unquote('"\\e93f"');
$pi-more-1-line: unquote('"\\e940"');
$pi-more-2-line: unquote('"\\e941"');
$pi-more-3-line: unquote('"\\e942"');
$pi-more-4-line: unquote('"\\e943"');
$pi-multiselect-line: unquote('"\\e944"');
$pi-newdot-line: unquote('"\\e945"');
$pi-open-door-line: unquote('"\\e946"');
$pi-plus-line: unquote('"\\e947"');
$pi-polygon-line: unquote('"\\e948"');
$pi-power-line: unquote('"\\e949"');
$pi-process-line: unquote('"\\e94a"');
$pi-question-line: unquote('"\\e94b"');
$pi-radiobox-line: unquote('"\\e94c"');
$pi-recycle-line: unquote('"\\e94d"');
$pi-refresh-1-line: unquote('"\\e94e"');
$pi-refresh-2-line: unquote('"\\e94f"');
$pi-refresh-3-line: unquote('"\\e950"');
$pi-refresh-4-line: unquote('"\\e951"');
$pi-refresh-anticlockwise-1-line: unquote('"\\e952"');
$pi-report-line: unquote('"\\e953"');
$pi-restore-line: unquote('"\\e954"');
$pi-safe-alert-line: unquote('"\\e955"');
$pi-safe-flash-line: unquote('"\\e956"');
$pi-safe-lock-line: unquote('"\\e957"');
$pi-safe-shield-2-line: unquote('"\\e958"');
$pi-safe-shield-line: unquote('"\\e959"');
$pi-safety-certificate-line: unquote('"\\e95a"');
$pi-settings-1-line: unquote('"\\e95b"');
$pi-settings-2-line: unquote('"\\e95c"');
$pi-settings-3-line: unquote('"\\e95d"');
$pi-settings-4-line: unquote('"\\e95e"');
$pi-settings-5-line: unquote('"\\e95f"');
$pi-settings-6-line: unquote('"\\e960"');
$pi-settings-7-line: unquote('"\\e961"');
$pi-share-2-line: unquote('"\\e962"');
$pi-share-3-line: unquote('"\\e963"');
$pi-air-balloon-line: unquote('"\\e964"');
$pi-american-football-line: unquote('"\\e965"');
$pi-backboard-line: unquote('"\\e966"');
$pi-badminton-line: unquote('"\\e967"');
$pi-barbell-line: unquote('"\\e968"');
$pi-baseball-2-line: unquote('"\\e969"');
$pi-baseball-line: unquote('"\\e96a"');
$pi-basketball-line: unquote('"\\e96b"');
$pi-beachball-line: unquote('"\\e96c"');
$pi-clubs-line: unquote('"\\e96d"');
$pi-cylinder-line: unquote('"\\e96e"');
$pi-diamond-line: unquote('"\\e96f"');
$pi-diamond-square-line: unquote('"\\e970"');
$pi-disabled-2-line: unquote('"\\e971"');
$pi-disabled-line: unquote('"\\e972"');
$pi-fitness-line: unquote('"\\e973"');
$pi-football-line: unquote('"\\e974"');
$pi-heart-crack-line: unquote('"\\e975"');
$pi-heart-half-line: unquote('"\\e976"');
$pi-heart-line: unquote('"\\e977"');
$pi-hexagon-line: unquote('"\\e978"');
$pi-line-line: unquote('"\\e984"');
$pi-octagon-line: unquote('"\\e985"');
$pi-parachute-line: unquote('"\\e986"');
$pi-pentagon-line: unquote('"\\e987"');
$pi-pingpong-line: unquote('"\\e988"');
$pi-playground-line: unquote('"\\e989"');
$pi-rectangle-line: unquote('"\\e98a"');
$pi-rectangle-vertical-line: unquote('"\\e98b"');
$pi-riding-line: unquote('"\\e98c"');
$pi-round-line: unquote('"\\e98d"');
$pi-run-line: unquote('"\\e98e"');
$pi-sector-line: unquote('"\\e98f"');
$pi-shield-shape-line: unquote('"\\e990"');
$pi-skateboard-line: unquote('"\\e991"');
$pi-spade-line: unquote('"\\e992"');
$pi-square-line: unquote('"\\e993"');
$pi-star-half-line: unquote('"\\e994"');
$pi-star-line: unquote('"\\e995"');
$pi-surfboard-line: unquote('"\\e996"');
$pi-swimming-pool-line: unquote('"\\e997"');
$pi-sword-line: unquote('"\\e998"');
$pi-toxophily-line: unquote('"\\e999"');
$pi-triangle-line: unquote('"\\e99a"');
$pi-volleyball-line: unquote('"\\e99b"');
$pi-walk-line: unquote('"\\e99c"');
$pi-wheelchair-line: unquote('"\\e99d"');
$pi-whistle-line: unquote('"\\e99e"');
$pi-baby-line: unquote('"\\e99f"');
$pi-beard-line: unquote('"\\e9a0"');
$pi-body-line: unquote('"\\e9a1"');
$pi-bow-tie-line: unquote('"\\e9a2"');
$pi-bowknot-line: unquote('"\\e9a3"');
$pi-brain-line: unquote('"\\e9a4"');
$pi-brief-line: unquote('"\\e9a5"');
$pi-chef-hat-line: unquote('"\\e9a6"');
$pi-christmas-hat-line: unquote('"\\e9a7"');
$pi-deer-line: unquote('"\\e9a8"');
$pi-dental-line: unquote('"\\e9a9"');
$pi-dress-line: unquote('"\\e9aa"');
$pi-ear-line: unquote('"\\e9ab"');
$pi-eye-2-line: unquote('"\\e9ac"');
$pi-eyebrow-line: unquote('"\\e9ad"');
$pi-face-line: unquote('"\\e9ae"');
$pi-father-christmas-line: unquote('"\\e9af"');
$pi-finger-press-line: unquote('"\\e9b0"');
$pi-finger-Rock-line: unquote('"\\e9b1"');
$pi-finger-swipe-line: unquote('"\\e9b2"');
$pi-finger-tap-line: unquote('"\\e9b3"');
$pi-glove-line: unquote('"\\e9b4"');
$pi-hair-2-line: unquote('"\\e9b5"');
$pi-hair-line: unquote('"\\e9b6"');
$pi-hand-finger-2-line: unquote('"\\e9b7"');
$pi-hand-finger-line: unquote('"\\e9b8"');
$pi-hand-grab-line: unquote('"\\e9b9"');
$pi-hand-line: unquote('"\\e9ba"');
$pi-hand-two-fingers-line: unquote('"\\e9bb"');
$pi-hands-clapping-line: unquote('"\\e9bc"');
$pi-hat-2-line: unquote('"\\e9bd"');
$pi-hat-line: unquote('"\\e9be"');
$pi-head-line: unquote('"\\e9bf"');
$pi-heart-hand-line: unquote('"\\e9c0"');
$pi-heartbeat-2-line: unquote('"\\e9c1"');
$pi-incognito-mode-line: unquote('"\\e9c2"');
$pi-lungs-line: unquote('"\\e9c3"');
$pi-middle-finger-line: unquote('"\\e9c4"');
$pi-mouth-line: unquote('"\\e9c5"');
$pi-necktie-line: unquote('"\\e9c6"');
$pi-nose-line: unquote('"\\e9c7"');
$pi-pray-line: unquote('"\\e9c8"');
$pi-scarf-line: unquote('"\\e9c9"');
$pi-shirt-line: unquote('"\\e9ca"');
$pi-shoe-2-line: unquote('"\\e9cb"');
$pi-shoe-line: unquote('"\\e9cc"');
$pi-shorts-line: unquote('"\\e9cd"');
$pi-skirt-line: unquote('"\\e9ce"');
$pi-skull-line: unquote('"\\e9cf"');
$pi-sock-line: unquote('"\\e9d0"');
$pi-t-shirt-2-line: unquote('"\\e9d1"');
$pi-trouser-line: unquote('"\\e9d2"');
$pi-vest-line: unquote('"\\e9d3"');
$pi-celsius-line: unquote('"\\e9d4"');
$pi-cloud-2-line: unquote('"\\e9d5"');
$pi-cloud-lightning-line: unquote('"\\e9d6"');
$pi-cloud-line: unquote('"\\e9d7"');
$pi-cloud-snow-line: unquote('"\\e9d8"');
$pi-cloud-windy-line: unquote('"\\e9d9"');
$pi-clouds-line: unquote('"\\e9da"');
$pi-drizzle-line: unquote('"\\e9db"');
$pi-dry-line: unquote('"\\e9dc"');
$pi-fahrenheit-line: unquote('"\\e9dd"');
$pi-floating-dust-line: unquote('"\\e9de"');
$pi-fog-line: unquote('"\\e9df"');
$pi-full-moon-line: unquote('"\\e9e0"');
$pi-hail-line: unquote('"\\e9e1"');
$pi-haze-line: unquote('"\\e9e2"');
$pi-heavy-rain-line: unquote('"\\e9e3"');
$pi-heavy-rainstorm-line: unquote('"\\e9e4"');
$pi-heavy-snow-line: unquote('"\\e9e5"');
$pi-heavy-snowstorm-line: unquote('"\\e9e6"');
$pi-high-temperature-line: unquote('"\\e9e7"');
$pi-light-snow-line: unquote('"\\e9e8"');
$pi-lightning-line: unquote('"\\e9e9"');
$pi-low-temperature-line: unquote('"\\e9ea"');
$pi-moderate-snow-line: unquote('"\\e9eb"');
$pi-moon-cloudy-line: unquote('"\\e9ec"');
$pi-moon-fog-line: unquote('"\\e9ed"');
$pi-moon-line: unquote('"\\e9ee"');
$pi-moon-stars-line: unquote('"\\e9ef"');
$pi-moonlight-line: unquote('"\\e9f0"');
$pi-na-line: unquote('"\\e9f1"');
$pi-partly-cloud-daytime-line: unquote('"\\e9f2"');
$pi-partly-cloud-night-line: unquote('"\\e9f3"');
$pi-rain-line: unquote('"\\e9f4"');
$pi-rainbow-line: unquote('"\\e9f5"');
$pi-rainstorm-line: unquote('"\\e9f6"');
$pi-sandstorm-line: unquote('"\\e9f7"');
$pi-showers-line: unquote('"\\e9f8"');
$pi-snow-line: unquote('"\\e9f9"');
$pi-snowflake-line: unquote('"\\e9fa"');
$pi-snowman-line: unquote('"\\e9fb"');
$pi-snowstorm-2-line: unquote('"\\e9fc"');
$pi-snowstorm-line: unquote('"\\e9fd"');
$pi-sparkles-2-line: unquote('"\\e9fe"');
$pi-sparkles-line: unquote('"\\e9ff"');
$pi-sun-2-line: unquote('"\\ea00"');
$pi-sun-cloudy-line: unquote('"\\ea01"');
$pi-sun-fog-line: unquote('"\\ea02"');
$pi-sun-line: unquote('"\\ea03"');
$pi-sunrise-line: unquote('"\\ea04"');
$pi-sunset-line: unquote('"\\ea05"');
$pi-thermometer-line: unquote('"\\ea06"');
$pi-thunderstorm-line: unquote('"\\ea07"');
$pi-tornado-2-line: unquote('"\\ea08"');
$pi-tornado-line: unquote('"\\ea09"');
$pi-typhoon-line: unquote('"\\ea0a"');
$pi-umbrella-line: unquote('"\\ea0b"');
$pi-user-4-line: unquote('"\\ea0c"');
$pi-user-5-line: unquote('"\\ea0d"');
$pi-user-add-2-line: unquote('"\\ea0e"');
$pi-user-add-line: unquote('"\\ea0f"');
$pi-user-edit-line: unquote('"\\ea10"');
$pi-user-follow-2-line: unquote('"\\ea11"');
$pi-user-follow-line: unquote('"\\ea12"');
$pi-user-forbid-line: unquote('"\\ea13"');
$pi-user-heart-line: unquote('"\\ea14"');
$pi-user-hide-line: unquote('"\\ea15"');
$pi-user-info-line: unquote('"\\ea16"');
$pi-user-lock-line: unquote('"\\ea17"');
$pi-user-pin-line: unquote('"\\ea18"');
$pi-user-question-line: unquote('"\\ea19"');
$pi-user-remove-2-line: unquote('"\\ea1a"');
$pi-user-remove-line: unquote('"\\ea1b"');
$pi-user-search-line: unquote('"\\ea1c"');
$pi-user-security-line: unquote('"\\ea1d"');
$pi-user-setting-line: unquote('"\\ea1e"');
$pi-user-star-line: unquote('"\\ea1f"');
$pi-user-visible-line: unquote('"\\ea20"');
$pi-user-warning-line: unquote('"\\ea21"');
$pi-user-x-line: unquote('"\\ea22"');
$pi-wave-line: unquote('"\\ea23"');
$pi-wet-line: unquote('"\\ea24"');
$pi-wind-line: unquote('"\\ea25"');
$pi-axe-line: unquote('"\\ea26"');
$pi-balloon-2-line: unquote('"\\ea27"');
$pi-bath-line: unquote('"\\ea28"');
$pi-bedside-table-2-line: unquote('"\\ea29"');
$pi-bedside-table-line: unquote('"\\ea2a"');
$pi-blessing-line: unquote('"\\ea2b"');
$pi-bomb-line: unquote('"\\ea2c"');
$pi-boom-line: unquote('"\\ea2d"');
$pi-bottle-line: unquote('"\\ea2e"');
$pi-capsule-line: unquote('"\\ea2f"');
$pi-coathanger-line: unquote('"\\ea30"');
$pi-cross-2-line: unquote('"\\ea31"');
$pi-crystal-ball-line: unquote('"\\ea32"');
$pi-curtain-line: unquote('"\\ea33"');
$pi-drawer-2-line: unquote('"\\ea34"');
$pi-drawer-line: unquote('"\\ea35"');
$pi-face-mask-line: unquote('"\\ea36"');
$pi-firecracker-line: unquote('"\\ea37"');
$pi-firework-line: unquote('"\\ea38"');
$pi-first-aid-kit-line: unquote('"\\ea39"');
$pi-hammer-line: unquote('"\\ea3a"');
$pi-hand-card-line: unquote('"\\ea3b"');
$pi-hand-heart-line: unquote('"\\ea3c"');
$pi-hoe-line: unquote('"\\ea3d"');
$pi-injection-line: unquote('"\\ea3e"');
$pi-kite-line: unquote('"\\ea3f"');
$pi-nurse-line: unquote('"\\ea40"');
$pi-pickax-line: unquote('"\\ea41"');
$pi-pot-line: unquote('"\\ea42"');
$pi-prescription-line: unquote('"\\ea43"');
$pi-rake-line: unquote('"\\ea44"');
$pi-random-line: unquote('"\\ea45"');
$pi-screwdriver-line: unquote('"\\ea46"');
$pi-shovel-line: unquote('"\\ea47"');
$pi-shower-gel-line: unquote('"\\ea48"');
$pi-star-2-line: unquote('"\\ea49"');
$pi-star-topper-line: unquote('"\\ea4a"');
$pi-stethoscope-line: unquote('"\\ea4b"');
$pi-thought-line: unquote('"\\ea4c"');
$pi-toilet-paper-line: unquote('"\\ea4d"');
$pi-virus-line: unquote('"\\ea4e"');
$pi-wardrobe-2-line: unquote('"\\ea4f"');
$pi-wardrobe-line: unquote('"\\ea50"');
$pi-yinyang-line: unquote('"\\ea51"');
$pi-abs-line: unquote('"\\ea52"');
$pi-aerial-lift-line: unquote('"\\ea53"');
$pi-airplane-line: unquote('"\\ea54"');
$pi-auto-hold-line: unquote('"\\ea55"');
$pi-baby-carriage-line: unquote('"\\ea56"');
$pi-badge-line: unquote('"\\ea57"');
$pi-bike-line: unquote('"\\ea58"');
$pi-brake-line: unquote('"\\ea59"');
$pi-bus-2-line: unquote('"\\ea5a"');
$pi-bus-line: unquote('"\\ea5b"');
$pi-car-2-line: unquote('"\\ea5c"');
$pi-car-3-line: unquote('"\\ea5d"');
$pi-car-door-line: unquote('"\\ea5e"');
$pi-car-line: unquote('"\\ea5f"');
$pi-car-window-line: unquote('"\\ea60"');
$pi-charging-pile-line: unquote('"\\ea61"');
$pi-contacts-2-line: unquote('"\\ea62"');
$pi-contacts-3-line: unquote('"\\ea63"');
$pi-contacts-4-line: unquote('"\\ea64"');
$pi-contacts-line: unquote('"\\ea65"');
$pi-ebike-line: unquote('"\\ea66"');
$pi-emergency-flashers-line: unquote('"\\ea67"');
$pi-engine-line: unquote('"\\ea68"');
$pi-fan-2-line: unquote('"\\ea69"');
$pi-fan-direction-down-line: unquote('"\\ea6a"');
$pi-fan-direction-front-line: unquote('"\\ea6b"');
$pi-fan-direction-up-line: unquote('"\\ea6c"');
$pi-fan-line: unquote('"\\ea6d"');
$pi-female-line: unquote('"\\ea6e"');
$pi-flight-inflight-line: unquote('"\\ea6f"');
$pi-flight-land-line: unquote('"\\ea70"');
$pi-flight-takeoff-line: unquote('"\\ea71"');
$pi-four-wheel-drive-line: unquote('"\\ea72"');
$pi-front-fog-lights-line: unquote('"\\ea73"');
$pi-front-windshield-defroster-line: unquote('"\\ea74"');
$pi-gas-station-line: unquote('"\\ea75"');
$pi-group-2-line: unquote('"\\ea76"');
$pi-group-3-line: unquote('"\\ea77"');
$pi-group-line: unquote('"\\ea78"');
$pi-hight-beam-headlights-line: unquote('"\\ea79"');
$pi-hood-line: unquote('"\\ea7a"');
$pi-idcard-line: unquote('"\\ea7b"');
$pi-jeep-line: unquote('"\\ea7c"');
$pi-low-beam-headlights-line: unquote('"\\ea7d"');
$pi-male-line: unquote('"\\ea7e"');
$pi-oil-line: unquote('"\\ea7f"');
$pi-park-line: unquote('"\\ea80"');
$pi-parking-lights-line: unquote('"\\ea81"');
$pi-pinwheel-2-line: unquote('"\\ea82"');
$pi-pinwheel-line: unquote('"\\ea83"');
$pi-rare-fog-lights-line: unquote('"\\ea84"');
$pi-rear-windshield-defroster-line: unquote('"\\ea85"');
$pi-rocket-2-line: unquote('"\\ea86"');
$pi-rocket-line: unquote('"\\ea87"');
$pi-rudder-line: unquote('"\\ea88"');
$pi-sailboat-line: unquote('"\\ea89"');
$pi-scooter-line: unquote('"\\ea8a"');
$pi-seat-heated-line: unquote('"\\ea8b"');
$pi-seat-line: unquote('"\\ea8c"');
$pi-share-forward-line: unquote('"\\ea8d"');
$pi-shield-line: unquote('"\\ea8e"');
$pi-ship-line: unquote('"\\ea8f"');
$pi-sleep-line: unquote('"\\ea90"');
$pi-sleigh-line: unquote('"\\ea91"');
$pi-steering-wheel-line: unquote('"\\ea92"');
$pi-switch-line: unquote('"\\ea93"');
$pi-t-shirt-line: unquote('"\\ea94"');
$pi-tank-line: unquote('"\\ea95"');
$pi-three-circles-line: unquote('"\\ea96"');
$pi-thumb-down-2-line: unquote('"\\ea97"');
$pi-thumb-down-line: unquote('"\\ea98"');
$pi-thumb-up-2-line: unquote('"\\ea99"');
$pi-thumb-up-line: unquote('"\\ea9a"');
$pi-time-line: unquote('"\\ea9b"');
$pi-toggle-left-2-line: unquote('"\\ea9c"');
$pi-toggle-left-line: unquote('"\\ea9d"');
$pi-toggle-right-2-line: unquote('"\\ea9e"');
$pi-toggle-Right-line: unquote('"\\ea9f"');
$pi-tool-line: unquote('"\\eaa0"');
$pi-traffic-lights-line: unquote('"\\eaa1"');
$pi-train-2-line: unquote('"\\eaa2"');
$pi-train-3-line: unquote('"\\eaa3"');
$pi-train-4-line: unquote('"\\eaa4"');
$pi-train-line: unquote('"\\eaa5"');
$pi-transformation-line: unquote('"\\eaa6"');
$pi-truck-line: unquote('"\\eaa7"');
$pi-trunk-line: unquote('"\\eaa8"');
$pi-tyre-line: unquote('"\\eaa9"');
$pi-ufo-2-line: unquote('"\\eaaa"');
$pi-ufo-line: unquote('"\\eaab"');
$pi-unlock-line: unquote('"\\eaac"');
$pi-user-1-line: unquote('"\\eaad"');
$pi-user-2-line: unquote('"\\eaae"');
$pi-user-3-line: unquote('"\\eaaf"');
$pi-user-4-line1: unquote('"\\eab0"');
$pi-version-line: unquote('"\\eab1"');
$pi-warning-line: unquote('"\\eab2"');
$pi-wastebasket-line: unquote('"\\eab3"');
$pi-wheel-line: unquote('"\\eab4"');
$pi-wiper-line: unquote('"\\eab5"');
$pi-bear-line: unquote('"\\eab6"');
$pi-bird-line: unquote('"\\eab7"');
$pi-butterfly-2-line: unquote('"\\eab8"');
$pi-butterfly-line: unquote('"\\eab9"');
$pi-cactus-2-line: unquote('"\\eaba"');
$pi-cactus-line: unquote('"\\eabb"');
$pi-campfire-line: unquote('"\\eabc"');
$pi-cat-line: unquote('"\\eabd"');
$pi-chess-line: unquote('"\\eabe"');
$pi-dandelion-line: unquote('"\\eabf"');
$pi-dog-line: unquote('"\\eac0"');
$pi-dragonfly-line: unquote('"\\eac1"');
$pi-flower-2-line: unquote('"\\eac2"');
$pi-flower-3-line: unquote('"\\eac3"');
$pi-flower-4-line: unquote('"\\eac4"');
$pi-flower-line: unquote('"\\eac5"');
$pi-flowerpot-line: unquote('"\\eac6"');
$pi-grass-line: unquote('"\\eac7"');
$pi-leaf-2-line: unquote('"\\eac8"');
$pi-leaf-3-line: unquote('"\\eac9"');
$pi-leaf-line: unquote('"\\eaca"');
$pi-lotus-line: unquote('"\\eacb"');
$pi-maple-leaf-line: unquote('"\\eacc"');
$pi-mickeymouse-line: unquote('"\\eacd"');
$pi-mountain-2-line: unquote('"\\eace"');
$pi-mountain-line: unquote('"\\eacf"');
$pi-mushroom-line: unquote('"\\ead0"');
$pi-paw-line: unquote('"\\ead1"');
$pi-pig-line: unquote('"\\ead2"');
$pi-pumpkin-line: unquote('"\\ead3"');
$pi-rabbit-line: unquote('"\\ead4"');
$pi-sunflower-line: unquote('"\\ead5"');
$pi-toy-horse-line: unquote('"\\ead6"');
$pi-tree-2-line: unquote('"\\ead7"');
$pi-tree-3-line: unquote('"\\ead8"');
$pi-tree-4-line: unquote('"\\ead9"');
$pi-tree-line: unquote('"\\eada"');
$pi-wreath-line: unquote('"\\eadb"');
$pi-airpods-line: unquote('"\\eadc"');
$pi-album-line: unquote('"\\eadd"');
$pi-announcement-line: unquote('"\\eade"');
$pi-audio-tape-line: unquote('"\\eadf"');
$pi-bell-ringing-line: unquote('"\\eae0"');
$pi-camcorder-3-line: unquote('"\\eae1"');
$pi-camcorder-line: unquote('"\\eae2"');
$pi-camcorder-off-line: unquote('"\\eae3"');
$pi-camera-2-line: unquote('"\\eae4"');
$pi-camera-2-off-line: unquote('"\\eae5"');
$pi-camera-line: unquote('"\\eae6"');
$pi-camera-rotate-line: unquote('"\\eae7"');
$pi-clapperboard-line: unquote('"\\eae8"');
$pi-danmaku-line: unquote('"\\eae9"');
$pi-danmaku-off-line: unquote('"\\eaea"');
$pi-danmaku-on-line: unquote('"\\eaeb"');
$pi-disc-line: unquote('"\\eaec"');
$pi-expand-player-line: unquote('"\\eaed"');
$pi-fast-forward-line: unquote('"\\eaee"');
$pi-fast-rewind-line: unquote('"\\eaef"');
$pi-film-line: unquote('"\\eaf0"');
$pi-fullscreen-exit-line: unquote('"\\eaf1"');
$pi-fullscreen-line: unquote('"\\eaf2"');
$pi-headphone-2-line: unquote('"\\eaf3"');
$pi-headphone-line: unquote('"\\eaf4"');
$pi-horn-2-line: unquote('"\\eaf5"');
$pi-horn-line: unquote('"\\eaf6"');
$pi-live-line: unquote('"\\eaf7"');
$pi-live-photo-line: unquote('"\\eaf8"');
$pi-mic-2-line: unquote('"\\eaf9"');
$pi-mic-line: unquote('"\\eafa"');
$pi-mic-off-line: unquote('"\\eafb"');
$pi-microphone-line: unquote('"\\eafc"');
$pi-miniplayer-line: unquote('"\\eafd"');
$pi-movie-line: unquote('"\\eafe"');
$pi-music-2-line: unquote('"\\eaff"');
$pi-music-3-line: unquote('"\\eb00"');
$pi-music-line: unquote('"\\eb01"');
$pi-notification-line: unquote('"\\eb02"');
$pi-notification-newdot-line: unquote('"\\eb03"');
$pi-notification-off-line: unquote('"\\eb04"');
$pi-pause-circle-line: unquote('"\\eb05"');
$pi-pause-line: unquote('"\\eb06"');
$pi-play-circle-line: unquote('"\\eb07"');
$pi-play-line: unquote('"\\eb08"');
$pi-playlist-2-line: unquote('"\\eb09"');
$pi-playlist-line: unquote('"\\eb0a"');
$pi-record-mail-line: unquote('"\\eb0b"');
$pi-repeat-line: unquote('"\\eb0c"');
$pi-repeat-one-line: unquote('"\\eb0d"');
$pi-rotate-to-horizontal-line: unquote('"\\eb0e"');
$pi-rotate-to-vertical-line: unquote('"\\eb0f"');
$pi-service-line: unquote('"\\eb10"');
$pi-shuffle-2-line: unquote('"\\eb11"');
$pi-shuffle-line: unquote('"\\eb12"');
$pi-skip-forward-line: unquote('"\\eb13"');
$pi-skip-previous-line: unquote('"\\eb14"');
$pi-sound-line-line: unquote('"\\eb15"');
$pi-stop-circle-line: unquote('"\\eb16"');
$pi-stop-line: unquote('"\\eb17"');
$pi-video-camera-2-line: unquote('"\\eb18"');
$pi-video-camera-line: unquote('"\\eb19"');
$pi-video-line: unquote('"\\eb1a"');
$pi-voice-2-line: unquote('"\\eb1b"');
$pi-voice-line: unquote('"\\eb1c"');
$pi-volume-line: unquote('"\\eb1d"');
$pi-volume-mute-line: unquote('"\\eb1e"');
$pi-volume-off-line: unquote('"\\eb1f"');
$pi-aiming-2-line: unquote('"\\eb20"');
$pi-aiming-line: unquote('"\\eb21"');
$pi-anchor-line: unquote('"\\eb22"');
$pi-bed-2-line: unquote('"\\eb23"');
$pi-bed-line: unquote('"\\eb24"');
$pi-clock-2-line: unquote('"\\eb25"');
$pi-clock-line: unquote('"\\eb26"');
$pi-compass-3-line: unquote('"\\eb27"');
$pi-compass-line: unquote('"\\eb28"');
$pi-directions-2-line: unquote('"\\eb29"');
$pi-directions-line: unquote('"\\eb2a"');
$pi-earth-2-line: unquote('"\\eb2b"');
$pi-earth-3-line: unquote('"\\eb2c"');
$pi-earth-latitude-line: unquote('"\\eb2d"');
$pi-earth-line: unquote('"\\eb2e"');
$pi-earth-longitude-line: unquote('"\\eb2f"');
$pi-foot-line: unquote('"\\eb30"');
$pi-hours-line: unquote('"\\eb31"');
$pi-lifebuoy-line: unquote('"\\eb32"');
$pi-live-location-line: unquote('"\\eb33"');
$pi-location-2-line: unquote('"\\eb34"');
$pi-location-3-line: unquote('"\\eb35"');
$pi-location-line: unquote('"\\eb36"');
$pi-map-2-line: unquote('"\\eb37"');
$pi-map-line: unquote('"\\eb38"');
$pi-map-pin-line: unquote('"\\eb39"');
$pi-navigation-line: unquote('"\\eb3a"');
$pi-parking-line: unquote('"\\eb3b"');
$pi-planet-line: unquote('"\\eb3c"');
$pi-rest-area-line: unquote('"\\eb3d"');
$pi-road-line: unquote('"\\eb3e"');
$pi-route-line: unquote('"\\eb3f"');
$pi-traffic-cone-line: unquote('"\\eb40"');
$pi-tunnel-line: unquote('"\\eb41"');
$pi-umbrella-2-line: unquote('"\\eb42"');
$pi-world-2-line: unquote('"\\eb43"');
$pi-world-line: unquote('"\\eb44"');
$pi-airbnb-line: unquote('"\\eb45"');
$pi-airdrop-line: unquote('"\\eb46"');
$pi-alipay-line: unquote('"\\eb47"');
$pi-android-2-line: unquote('"\\eb48"');
$pi-android-line: unquote('"\\eb49"');
$pi-apple-line: unquote('"\\eb4a"');
$pi-appstore-line: unquote('"\\eb4b"');
$pi-arc-browser-line: unquote('"\\eb4c"');
$pi-behance-line: unquote('"\\eb4d"');
$pi-bluesky-social-line: unquote('"\\eb4e"');
$pi-carplay-line: unquote('"\\eb4f"');
$pi-chrome-line: unquote('"\\eb50"');
$pi-codepen-line: unquote('"\\eb51"');
$pi-dingtalk-line: unquote('"\\eb52"');
$pi-discord-line: unquote('"\\eb53"');
$pi-dribbble-line: unquote('"\\eb54"');
$pi-drive-line: unquote('"\\eb55"');
$pi-dropbox-line: unquote('"\\eb56"');
$pi-edge-line: unquote('"\\eb57"');
$pi-facebook-line: unquote('"\\eb58"');
$pi-figma-line: unquote('"\\eb59"');
$pi-firefox-line: unquote('"\\eb5a"');
$pi-git-lab-line: unquote('"\\eb5b"');
$pi-github-2-line: unquote('"\\eb5c"');
$pi-github-line: unquote('"\\eb5d"');
$pi-google-line: unquote('"\\eb5e"');
$pi-google-play-line: unquote('"\\eb5f"');
$pi-grok-line: unquote('"\\eb60"');
$pi-gumroad-line: unquote('"\\eb61"');
$pi-ins-line: unquote('"\\eb62"');
$pi-kakao-talk-line: unquote('"\\eb63"');
$pi-lemon-squeezy-line: unquote('"\\eb64"');
$pi-line-app-line: unquote('"\\eb65"');
$pi-linear-line: unquote('"\\eb66"');
$pi-linkedin-line: unquote('"\\eb67"');
$pi-linux-line: unquote('"\\eb68"');
$pi-mastercard-line: unquote('"\\eb69"');
$pi-mastodon-line: unquote('"\\eb6a"');
$pi-medium-line: unquote('"\\eb6b"');
$pi-messenger-line: unquote('"\\eb6c"');
$pi-meta-line: unquote('"\\eb6d"');
$pi-mingcute-line: unquote('"\\eb6e"');
$pi-moment-line: unquote('"\\eb6f"');
$pi-nfc-line: unquote('"\\eb70"');
$pi-nintendo-switch-line: unquote('"\\eb71"');
$pi-notion-line: unquote('"\\eb72"');
$pi-openai-line: unquote('"\\eb73"');
$pi-paypal-line: unquote('"\\eb74"');
$pi-pinterest-line: unquote('"\\eb75"');
$pi-qq-line: unquote('"\\eb76"');
$pi-react-line: unquote('"\\eb77"');
$pi-reddit-line: unquote('"\\eb78"');
$pi-safari-line: unquote('"\\eb79"');
$pi-snapchat-line: unquote('"\\eb7a"');
$pi-social-x-line: unquote('"\\eb7b"');
$pi-spotify-line: unquote('"\\eb7c"');
$pi-stripe-line: unquote('"\\eb7d"');
$pi-telegram-line: unquote('"\\eb7e"');
$pi-threads-line: unquote('"\\eb7f"');
$pi-tiktok-line: unquote('"\\eb80"');
$pi-trello-board-line: unquote('"\\eb81"');
$pi-twitter-line: unquote('"\\eb82"');
$pi-viber-messenger-line: unquote('"\\eb83"');
$pi-visa-line: unquote('"\\eb84"');
$pi-vkontakte-line: unquote('"\\eb85"');
$pi-vue-line: unquote('"\\eb86"');
$pi-wechat-line: unquote('"\\eb87"');
$pi-wechat-miniprogram-line: unquote('"\\eb88"');
$pi-wechat-pay-line: unquote('"\\eb89"');
$pi-weibo-line: unquote('"\\eb8a"');
$pi-whatsapp-line: unquote('"\\eb8b"');
$pi-windows-line: unquote('"\\eb8c"');
$pi-xbox-line: unquote('"\\eb8d"');
$pi-youtube-line: unquote('"\\eb8e"');
$pi-birthday-2-line: unquote('"\\eb8f"');
$pi-bone-line: unquote('"\\eb90"');
$pi-bottle-glass-line: unquote('"\\eb91"');
$pi-bowl-line: unquote('"\\eb92"');
$pi-bread-line: unquote('"\\eb93"');
$pi-cake-line: unquote('"\\eb94"');
$pi-candy-2-line: unquote('"\\eb95"');
$pi-candy-line: unquote('"\\eb96"');
$pi-carrot-line: unquote('"\\eb97"');
$pi-champagne-line: unquote('"\\eb98"');
$pi-chopsticks-line: unquote('"\\eb99"');
$pi-cookie-line: unquote('"\\eb9a"');
$pi-cookie-Man-line: unquote('"\\eb9b"');
$pi-cupcake-line: unquote('"\\eb9c"');
$pi-dinner-line: unquote('"\\eb9d"');
$pi-dish-cover-line: unquote('"\\eb9e"');
$pi-donut-line: unquote('"\\eb9f"');
$pi-drink-line: unquote('"\\eba0"');
$pi-egg-crack-line: unquote('"\\eba1"');
$pi-egg-line: unquote('"\\eba2"');
$pi-electric-cooker-line: unquote('"\\eba3"');
$pi-feeder-line: unquote('"\\eba4"');
$pi-fish-line: unquote('"\\eba5"');
$pi-fork-knife-line: unquote('"\\eba6"');
$pi-fork-line: unquote('"\\eba7"');
$pi-fork-spoon-line: unquote('"\\eba8"');
$pi-fried-egg-line: unquote('"\\eba9"');
$pi-fries-line: unquote('"\\ebaa"');
$pi-glass-cup-line: unquote('"\\ebab"');
$pi-hamburger-line: unquote('"\\ebac"');
$pi-ice-cream-2-line: unquote('"\\ebad"');
$pi-ice-cream-line: unquote('"\\ebae"');
$pi-lollipop-line: unquote('"\\ebaf"');
$pi-pizza-line: unquote('"\\ebb0"');
$pi-soup-pot-2-line: unquote('"\\ebb1"');
$pi-soup-pot-line: unquote('"\\ebb2"');
$pi-spatula-line: unquote('"\\ebb3"');
$pi-spoon-line: unquote('"\\ebb4"');
$pi-sugar-coated-haws-line: unquote('"\\ebb5"');
$pi-teacup-line: unquote('"\\ebb6"');
$pi-wine-line: unquote('"\\ebb7"');
$pi-wineglass-2-line: unquote('"\\ebb8"');
$pi-wineglass-line: unquote('"\\ebb9"');
$pi-xls-line: unquote('"\\ebba"');
$pi-zoom-in-line: unquote('"\\ebbb"');
$pi-zoom-out-line: unquote('"\\ebbc"');
$pi-album-2-line: unquote('"\\ebbd"');
$pi-archive-line: unquote('"\\ebbe"');
$pi-attachment-2-line: unquote('"\\ebbf"');
$pi-attachment-line: unquote('"\\ebc0"');
$pi-bill-2-line: unquote('"\\ebc1"');
$pi-bill-line: unquote('"\\ebc2"');
$pi-box-2-line: unquote('"\\ebc3"');
$pi-box-3-line: unquote('"\\ebc4"');
$pi-box-line: unquote('"\\ebc5"');
$pi-clipboard-line: unquote('"\\ebc6"');
$pi-copy-2-line: unquote('"\\ebc7"');
$pi-copy-3-line: unquote('"\\ebc8"');
$pi-copy-line: unquote('"\\ebc9"');
$pi-doc-line: unquote('"\\ebca"');
$pi-document-2-line: unquote('"\\ebcb"');
$pi-document-3-line: unquote('"\\ebcc"');
$pi-document-line: unquote('"\\ebcd"');
$pi-documents-line: unquote('"\\ebce"');
$pi-download-2-line: unquote('"\\ebcf"');
$pi-download-3-line: unquote('"\\ebd0"');
$pi-download-line: unquote('"\\ebd1"');
$pi-external-link-line: unquote('"\\ebd2"');
$pi-file-certificate-line: unquote('"\\ebd3"');
$pi-file-check-line: unquote('"\\ebd4"');
$pi-file-code-line: unquote('"\\ebd5"');
$pi-file-download-line: unquote('"\\ebd6"');
$pi-file-export-line: unquote('"\\ebd7"');
$pi-file-forbid-line: unquote('"\\ebd8"');
$pi-file-Import-line: unquote('"\\ebd9"');
$pi-file-Info-line: unquote('"\\ebda"');
$pi-file-line: unquote('"\\ebdb"');
$pi-file-locked-line: unquote('"\\ebdc"');
$pi-file-more-line: unquote('"\\ebdd"');
$pi-file-music-line: unquote('"\\ebde"');
$pi-file-new-line: unquote('"\\ebdf"');
$pi-file-search-line: unquote('"\\ebe0"');
$pi-file-security-line: unquote('"\\ebe1"');
$pi-file-star-line: unquote('"\\ebe2"');
$pi-file-unknown-line: unquote('"\\ebe3"');
$pi-file-upload-line: unquote('"\\ebe4"');
$pi-file-warning-line: unquote('"\\ebe5"');
$pi-file-zip-line: unquote('"\\ebe6"');
$pi-folder-2-line: unquote('"\\ebe7"');
$pi-folder-3-line: unquote('"\\ebe8"');
$pi-folder-check-line: unquote('"\\ebe9"');
$pi-folder-delete-line: unquote('"\\ebea"');
$pi-folder-download-line: unquote('"\\ebeb"');
$pi-folder-forbid-line: unquote('"\\ebec"');
$pi-folder-Info-line: unquote('"\\ebed"');
$pi-folder-line: unquote('"\\ebee"');
$pi-folder-locked-2-line: unquote('"\\ebef"');
$pi-folder-locked-line: unquote('"\\ebf0"');
$pi-folder-minus-line: unquote('"\\ebf1"');
$pi-folder-more-line: unquote('"\\ebf2"');
$pi-folder-open-2-line: unquote('"\\ebf3"');
$pi-folder-open-line: unquote('"\\ebf4"');
$pi-folder-security-line: unquote('"\\ebf5"');
$pi-folder-star-line: unquote('"\\ebf6"');
$pi-folder-upload-line: unquote('"\\ebf7"');
$pi-folder-warning-line: unquote('"\\ebf8"');
$pi-folder-zip-line: unquote('"\\ebf9"');
$pi-folders-line: unquote('"\\ebfa"');
$pi-Inbox-2-line: unquote('"\\ebfb"');
$pi-Inbox-line: unquote('"\\ebfc"');
$pi-Inventory-line: unquote('"\\ebfd"');
$pi-link-2-line: unquote('"\\ebfe"');
$pi-link-3-line: unquote('"\\ebff"');
$pi-link-line: unquote('"\\ec00"');
$pi-markdown-line: unquote('"\\ec01"');
$pi-new-folder-line: unquote('"\\ec02"');
$pi-news-2-line: unquote('"\\ec03"');
$pi-news-line: unquote('"\\ec04"');
$pi-package-2-line: unquote('"\\ec05"');
$pi-package-line: unquote('"\\ec06"');
$pi-paper-line: unquote('"\\ec07"');
$pi-passport-line: unquote('"\\ec08"');
$pi-pdf-line: unquote('"\\ec09"');
$pi-photo-album-2-line: unquote('"\\ec0a"');
$pi-photo-album-line: unquote('"\\ec0b"');
$pi-pic-2-line: unquote('"\\ec0c"');
$pi-pic-line: unquote('"\\ec0d"');
$pi-pin-2-line: unquote('"\\ec0e"');
$pi-pin-line: unquote('"\\ec0f"');
$pi-ppt-line: unquote('"\\ec10"');
$pi-profile-line: unquote('"\\ec11"');
$pi-report-forms-line: unquote('"\\ec12"');
$pi-save-2-line: unquote('"\\ec13"');
$pi-save-line: unquote('"\\ec14"');
$pi-search-2-line: unquote('"\\ec15"');
$pi-search-3-line: unquote('"\\ec16"');
$pi-search-line: unquote('"\\ec17"');
$pi-task-2-line: unquote('"\\ec18"');
$pi-task-line: unquote('"\\ec19"');
$pi-to-do-line: unquote('"\\ec1a"');
$pi-unarchive-line: unquote('"\\ec1b"');
$pi-unlink-2-line: unquote('"\\ec1c"');
$pi-unlink-line: unquote('"\\ec1d"');
$pi-upload-2-line: unquote('"\\ec1e"');
$pi-upload-3-line: unquote('"\\ec1f"');
$pi-upload-line: unquote('"\\ec20"');
$pi-angel-line: unquote('"\\ec21"');
$pi-angry-line: unquote('"\\ec22"');
$pi-confused-line: unquote('"\\ec23"');
$pi-emoji-2-line: unquote('"\\ec24"');
$pi-emoji-line: unquote('"\\ec25"');
$pi-happy-line: unquote('"\\ec26"');
$pi-look-down-line: unquote('"\\ec27"');
$pi-look-left-line: unquote('"\\ec28"');
$pi-look-right-line: unquote('"\\ec29"');
$pi-look-up-line: unquote('"\\ec2a"');
$pi-omg-line: unquote('"\\ec2b"');
$pi-puzzled-line: unquote('"\\ec2c"');
$pi-sad-line: unquote('"\\ec2d"');
$pi-sick-line: unquote('"\\ec2e"');
$pi-silent-line: unquote('"\\ec2f"');
$pi-sob-line: unquote('"\\ec30"');
$pi-surprise-line: unquote('"\\ec31"');
$pi-sweats-line: unquote('"\\ec32"');
$pi-terror-line: unquote('"\\ec33"');
$pi-tongue-line: unquote('"\\ec34"');
$pi-unhappy-dizzy-line: unquote('"\\ec35"');
$pi-unhappy-line: unquote('"\\ec36"');
$pi-backpack-line: unquote('"\\ec37"');
$pi-balance-line: unquote('"\\ec38"');
$pi-black-board-2-line: unquote('"\\ec39"');
$pi-black-board-line: unquote('"\\ec3a"');
$pi-book-2-line: unquote('"\\ec3b"');
$pi-book-3-line: unquote('"\\ec3c"');
$pi-book-4-line: unquote('"\\ec3d"');
$pi-book-5-line: unquote('"\\ec3e"');
$pi-book-6-line: unquote('"\\ec3f"');
$pi-book-line: unquote('"\\ec40"');
$pi-bookmark-line: unquote('"\\ec41"');
$pi-bookmarks-line: unquote('"\\ec42"');
$pi-certificate-2-line: unquote('"\\ec43"');
$pi-compass-2-line: unquote('"\\ec44"');
$pi-counter-line: unquote('"\\ec45"');
$pi-desk-line: unquote('"\\ec46"');
$pi-diary-line: unquote('"\\ec47"');
$pi-drum-line: unquote('"\\ec48"');
$pi-eyeglass-line: unquote('"\\ec49"');
$pi-flask-2-line: unquote('"\\ec4a"');
$pi-flask-3-line: unquote('"\\ec4b"');
$pi-flask-line: unquote('"\\ec4c"');
$pi-folding-fan-line: unquote('"\\ec4d"');
$pi-globe-line: unquote('"\\ec4e"');
$pi-magnet-line: unquote('"\\ec4f"');
$pi-mortarboard-line: unquote('"\\ec50"');
$pi-notebook-2-line: unquote('"\\ec51"');
$pi-notebook-line: unquote('"\\ec52"');
$pi-science-line: unquote('"\\ec53"');
$pi-speech-line: unquote('"\\ec54"');
$pi-test-tube-line: unquote('"\\ec55"');
$pi-align-center-line: unquote('"\\ec56"');
$pi-align-justify-line: unquote('"\\ec57"');
$pi-align-left-line: unquote('"\\ec58"');
$pi-align-right-line: unquote('"\\ec59"');
$pi-and-line: unquote('"\\ec5a"');
$pi-asterisk-2-line: unquote('"\\ec5b"');
$pi-asterisk-line: unquote('"\\ec5c"');
$pi-at-line: unquote('"\\ec5d"');
$pi-az-sort-ascending-letters-line: unquote('"\\ec5e"');
$pi-az-sort-descending-letters-line: unquote('"\\ec5f"');
$pi-blockquote-line: unquote('"\\ec60"');
$pi-bold-line: unquote('"\\ec61"');
$pi-column-line: unquote('"\\ec62"');
$pi-cursor-text-line: unquote('"\\ec63"');
$pi-dividing-line-line: unquote('"\\ec64"');
$pi-edit-2-line: unquote('"\\ec65"');
$pi-edit-3-line: unquote('"\\ec66"');
$pi-edit-4-line: unquote('"\\ec67"');
$pi-edit-line: unquote('"\\ec68"');
$pi-eraser-line: unquote('"\\ec69"');
$pi-font-line: unquote('"\\ec6a"');
$pi-font-size-line: unquote('"\\ec6b"');
$pi-formula-line: unquote('"\\ec6c"');
$pi-frame-line: unquote('"\\ec6d"');
$pi-hashtag-line: unquote('"\\ec6e"');
$pi-heading-1-line: unquote('"\\ec6f"');
$pi-heading-2-line: unquote('"\\ec70"');
$pi-heading-3-line: unquote('"\\ec71"');
$pi-indent-decrease-line: unquote('"\\ec72"');
$pi-indent-increase-line: unquote('"\\ec73"');
$pi-italic-line: unquote('"\\ec74"');
$pi-letter-spacing-line: unquote('"\\ec75"');
$pi-line-height-line: unquote('"\\ec76"');
$pi-list-check-2-line: unquote('"\\ec77"');
$pi-list-check-3-line: unquote('"\\ec78"');
$pi-list-check-line: unquote('"\\ec79"');
$pi-list-collapse-line: unquote('"\\ec7a"');
$pi-list-expansion-line: unquote('"\\ec7b"');
$pi-list-ordered-line: unquote('"\\ec7c"');
$pi-list-search-line: unquote('"\\ec7d"');
$pi-menu-line: unquote('"\\ec7e"');
$pi-numbers-09-sort-ascending-line: unquote('"\\ec7f"');
$pi-numbers-09-sort-descending-line: unquote('"\\ec80"');
$pi-numbers-90-sort-ascending-line: unquote('"\\ec81"');
$pi-numbers-90-sort-descending-line: unquote('"\\ec82"');
$pi-omega-line: unquote('"\\ec83"');
$pi-paint-line: unquote('"\\ec84"');
$pi-paragraph-line: unquote('"\\ec85"');
$pi-pen-2-line: unquote('"\\ec86"');
$pi-pencil-2-line: unquote('"\\ec87"');
$pi-pencil-3-line: unquote('"\\ec88"');
$pi-pencil-line: unquote('"\\ec89"');
$pi-pencil-ruler-line: unquote('"\\ec8a"');
$pi-quote-left-line: unquote('"\\ec8b"');
$pi-quote-right-line: unquote('"\\ec8c"');
$pi-signature-line: unquote('"\\ec8d"');
$pi-sort-ascending-line: unquote('"\\ec8e"');
$pi-sort-descending-line: unquote('"\\ec8f"');
$pi-space-line: unquote('"\\ec90"');
$pi-spacing-horizontal-line: unquote('"\\ec91"');
$pi-spacing-vertical-line: unquote('"\\ec92"');
$pi-strikethrough-line: unquote('"\\ec93"');
$pi-subtitle-line: unquote('"\\ec94"');
$pi-table-3-line: unquote('"\\ec95"');
$pi-table-line: unquote('"\\ec96"');
$pi-text-2-line: unquote('"\\ec97"');
$pi-text-area-line: unquote('"\\ec98"');
$pi-text-color-line: unquote('"\\ec99"');
$pi-text-direction-left-line: unquote('"\\ec9a"');
$pi-text-direction-right-line: unquote('"\\ec9b"');
$pi-text-line: unquote('"\\ec9c"');
$pi-textbox-line: unquote('"\\ec9d"');
$pi-translate-2-line: unquote('"\\ec9e"');
$pi-translate-line: unquote('"\\ec9f"');
$pi-underline-line: unquote('"\\eca0"');
$pi-za-sort-ascending-letters-line: unquote('"\\eca1"');
$pi-za-sort-descending-letters-line: unquote('"\\eca2"');
$pi-tower-crane-line: unquote('"\\eca3"');
$pi-tv-1-line: unquote('"\\eca4"');
$pi-tv-2-line: unquote('"\\eca5"');
$pi-usb-flash-disk-line: unquote('"\\eca6"');
$pi-usb-line: unquote('"\\eca7"');
$pi-vison-pro-line: unquote('"\\eca8"');
$pi-wash-machine-line: unquote('"\\eca9"');
$pi-watch-2-line: unquote('"\\ecaa"');
$pi-watch-line: unquote('"\\ecab"');
$pi-wifi-line: unquote('"\\ecac"');
$pi-wifi-off-line: unquote('"\\ecad"');
$pi-air-condition-line: unquote('"\\ecae"');
$pi-air-condition-open-line: unquote('"\\ecaf"');
$pi-airplay-line: unquote('"\\ecb0"');
$pi-airpods-2-line: unquote('"\\ecb1"');
$pi-alarm-1-line: unquote('"\\ecb2"');
$pi-alarm-2-line: unquote('"\\ecb3"');
$pi-aspect-ratio-line: unquote('"\\ecb4"');
$pi-Barcode-line: unquote('"\\ecb5"');
$pi-barcode-scan-line: unquote('"\\ecb6"');
$pi-base-station-2-line: unquote('"\\ecb7"');
$pi-Base-station-line: unquote('"\\ecb8"');
$pi-battery-1-line: unquote('"\\ecb9"');
$pi-battery-2-line: unquote('"\\ecba"');
$pi-battery-3-line: unquote('"\\ecbb"');
$pi-battery-4-line: unquote('"\\ecbc"');
$pi-Battery-automotive-line: unquote('"\\ecbd"');
$pi-battery-charging-line: unquote('"\\ecbe"');
$pi-battery-line: unquote('"\\ecbf"');
$pi-bluetooth-line: unquote('"\\ecc0"');
$pi-bluetooth-off-line: unquote('"\\ecc1"');
$pi-bulb-2-line: unquote('"\\ecc2"');
$pi-bulb-line: unquote('"\\ecc3"');
$pi-camcorder-2-line: unquote('"\\ecc4"');
$pi-cardboard-vr-line: unquote('"\\ecc5"');
$pi-ceiling-lamp-line: unquote('"\\ecc6"');
$pi-cellphone-2-horizontal-line: unquote('"\\ecc7"');
$pi-cellphone-2-line: unquote('"\\ecc8"');
$pi-cellphone-line: unquote('"\\ecc9"');
$pi-cellphone-vibration-line: unquote('"\\ecca"');
$pi-chip-line: unquote('"\\eccb"');
$pi-computer-camera-line: unquote('"\\eccc"');
$pi-computer-camera-off-line: unquote('"\\eccd"');
$pi-computer-line: unquote('"\\ecce"');
$pi-counter-2-line: unquote('"\\eccf"');
$pi-cube-3d-line: unquote('"\\ecd0"');
$pi-dashboard-2-line: unquote('"\\ecd1"');
$pi-dashboard-3-line: unquote('"\\ecd2"');
$pi-dashboard-4-line: unquote('"\\ecd3"');
$pi-dashboard-line: unquote('"\\ecd4"');
$pi-desk-lamp-2-line: unquote('"\\ecd5"');
$pi-desk-lamp-line: unquote('"\\ecd6"');
$pi-device-line: unquote('"\\ecd7"');
$pi-display-line: unquote('"\\ecd8"');
$pi-drone-line: unquote('"\\ecd9"');
$pi-escalator-down-line: unquote('"\\ecda"');
$pi-escalator-up-line: unquote('"\\ecdb"');
$pi-fax-line: unquote('"\\ecdc"');
$pi-flash-line: unquote('"\\ecdd"');
$pi-flashlight-line: unquote('"\\ecde"');
$pi-fridge-line: unquote('"\\ecdf"');
$pi-game-1-line: unquote('"\\ece0"');
$pi-game-2-line: unquote('"\\ece1"');
$pi-gradienter-line: unquote('"\\ece2"');
$pi-guitar-line: unquote('"\\ece3"');
$pi-high-voltage-power-line: unquote('"\\ece4"');
$pi-home-Wifi-line: unquote('"\\ece5"');
$pi-homepod-line: unquote('"\\ece6"');
$pi-homepod-mini-line: unquote('"\\ece7"');
$pi-imac-line: unquote('"\\ece8"');
$pi-instrument-line: unquote('"\\ece9"');
$pi-keyboard-2-line: unquote('"\\ecea"');
$pi-keyboard-line: unquote('"\\eceb"');
$pi-laptop-2-line: unquote('"\\ecec"');
$pi-laptop-line: unquote('"\\eced"');
$pi-light-line: unquote('"\\ecee"');
$pi-microscope-line: unquote('"\\ecef"');
$pi-midi-line: unquote('"\\ecf0"');
$pi-monitor-line: unquote('"\\ecf1"');
$pi-mouse-line: unquote('"\\ecf2"');
$pi-pad-line: unquote('"\\ecf3"');
$pi-panoramas-line: unquote('"\\ecf4"');
$pi-plugin-2-line: unquote('"\\ecf5"');
$pi-plugin-line: unquote('"\\ecf6"');
$pi-print-line: unquote('"\\ecf7"');
$pi-projector-line: unquote('"\\ecf8"');
$pi-projector-screen-line: unquote('"\\ecf9"');
$pi-qrcode-2-line: unquote('"\\ecfa"');
$pi-qrcode-line: unquote('"\\ecfb"');
$pi-radar-2-line: unquote('"\\ecfc"');
$pi-radar-line: unquote('"\\ecfd"');
$pi-radio-line: unquote('"\\ecfe"');
$pi-remote-control-line: unquote('"\\ecff"');
$pi-remote-line: unquote('"\\ed00"');
$pi-router-modem-line: unquote('"\\ed01"');
$pi-rss-2-line: unquote('"\\ed02"');
$pi-rss-line: unquote('"\\ed03"');
$pi-sandglass-2-line: unquote('"\\ed04"');
$pi-sandglass-line: unquote('"\\ed05"');
$pi-scan-2-line: unquote('"\\ed06"');
$pi-scan-line: unquote('"\\ed07"');
$pi-server-2-line: unquote('"\\ed08"');
$pi-server-line: unquote('"\\ed09"');
$pi-shower-line: unquote('"\\ed0a"');
$pi-signal-line: unquote('"\\ed0b"');
$pi-solar-panel-line: unquote('"\\ed0c"');
$pi-speaker-line: unquote('"\\ed0d"');
$pi-stopwatch-line: unquote('"\\ed0e"');
$pi-storage-line: unquote('"\\ed0f"');
$pi-telescope-2-line: unquote('"\\ed10"');
$pi-telescope-line: unquote('"\\ed11"');
$pi-braces-line: unquote('"\\ed12"');
$pi-brackets-angle-line: unquote('"\\ed13"');
$pi-brackets-line: unquote('"\\ed14"');
$pi-bug-line: unquote('"\\ed15"');
$pi-code-line: unquote('"\\ed16"');
$pi-command-line: unquote('"\\ed17"');
$pi-cursor-2-line: unquote('"\\ed18"');
$pi-cursor-3-line: unquote('"\\ed19"');
$pi-cursor-line: unquote('"\\ed1a"');
$pi-department-line: unquote('"\\ed1b"');
$pi-directory-line: unquote('"\\ed1c"');
$pi-git-branch-line: unquote('"\\ed1d"');
$pi-git-commit-line: unquote('"\\ed1e"');
$pi-git-compare-line: unquote('"\\ed1f"');
$pi-git-merge-line: unquote('"\\ed20"');
$pi-git-pull-request-close-line: unquote('"\\ed21"');
$pi-git-pull-request-line: unquote('"\\ed22"');
$pi-hotkey-line: unquote('"\\ed23"');
$pi-inspect-line: unquote('"\\ed24"');
$pi-parentheses-line: unquote('"\\ed25"');
$pi-performance-line: unquote('"\\ed26"');
$pi-sitemap-line: unquote('"\\ed27"');
$pi-terminal-box-line: unquote('"\\ed28"');
$pi-terminal-line: unquote('"\\ed29"');
$pi-web-line: unquote('"\\ed2a"');
$pi-scissors-line: unquote('"\\ed2b"');
$pi-screenshot-line: unquote('"\\ed2c"');
$pi-section-line: unquote('"\\ed2d"');
$pi-shadow-line: unquote('"\\ed2e"');
$pi-sticker-line: unquote('"\\ed2f"');
$pi-subtract-line: unquote('"\\ed30"');
$pi-table-2-line: unquote('"\\ed31"');
$pi-unfold-horizontal-line: unquote('"\\ed32"');
$pi-unfold-vertical-line: unquote('"\\ed33"');
$pi-union-line: unquote('"\\ed34"');
$pi-vector-bezier-2-line: unquote('"\\ed35"');
$pi-vector-bezier-3-line: unquote('"\\ed36"');
$pi-vector-bezier-line: unquote('"\\ed37"');
$pi-vector-group-line: unquote('"\\ed38"');
$pi-x-skew-line: unquote('"\\ed39"');
$pi-y-skew-line: unquote('"\\ed3a"');
$pi-align-bottom-line: unquote('"\\ed3b"');
$pi-align-horizontal-center-line: unquote('"\\ed3c"');
$pi-align-left-2-line: unquote('"\\ed3d"');
$pi-align-right-2-line: unquote('"\\ed3e"');
$pi-align-top-line: unquote('"\\ed3f"');
$pi-align-vertical-center-line: unquote('"\\ed40"');
$pi-anticlockwise-alt-line: unquote('"\\ed41"');
$pi-anticlockwise-line: unquote('"\\ed42"');
$pi-artboard-line: unquote('"\\ed43"');
$pi-background-line: unquote('"\\ed44"');
$pi-bling-line: unquote('"\\ed45"');
$pi-board-line: unquote('"\\ed46"');
$pi-border-blank-line: unquote('"\\ed47"');
$pi-border-bottom-line: unquote('"\\ed48"');
$pi-border-horizontal-line: unquote('"\\ed49"');
$pi-border-inner-line: unquote('"\\ed4a"');
$pi-border-left-line: unquote('"\\ed4b"');
$pi-border-outer-line: unquote('"\\ed4c"');
$pi-border-radius-line: unquote('"\\ed4d"');
$pi-border-right-line: unquote('"\\ed4e"');
$pi-border-top-line: unquote('"\\ed4f"');
$pi-border-vertical-line: unquote('"\\ed50"');
$pi-brightness-line: unquote('"\\ed51"');
$pi-brush-2-line: unquote('"\\ed52"');
$pi-clockwise-alt-line: unquote('"\\ed53"');
$pi-clockwise-line: unquote('"\\ed54"');
$pi-color-filter-line: unquote('"\\ed55"');
$pi-color-picker-line: unquote('"\\ed56"');
$pi-columns-2-line: unquote('"\\ed57"');
$pi-columns-3-line: unquote('"\\ed58"');
$pi-combine-line: unquote('"\\ed59"');
$pi-components-line: unquote('"\\ed5a"');
$pi-distribute-spacing-horizontal-line: unquote('"\\ed5b"');
$pi-distribute-spacing-vertical-line: unquote('"\\ed5c"');
$pi-drawing-board-line: unquote('"\\ed5d"');
$pi-drop-line: unquote('"\\ed5e"');
$pi-ease-in-control-point-line: unquote('"\\ed5f"');
$pi-ease-in-line: unquote('"\\ed60"');
$pi-ease-in-out-control-point-line: unquote('"\\ed61"');
$pi-ease-out-control-point-line: unquote('"\\ed62"');
$pi-ease-out-line: unquote('"\\ed63"');
$pi-easy-in-out-line: unquote('"\\ed64"');
$pi-exclude-line: unquote('"\\ed65"');
$pi-exposure-line: unquote('"\\ed66"');
$pi-flip-horizontal-line: unquote('"\\ed67"');
$pi-flip-vertical-line: unquote('"\\ed68"');
$pi-fold-horizontal-line: unquote('"\\ed69"');
$pi-fold-vertical-line: unquote('"\\ed6a"');
$pi-intersect-line: unquote('"\\ed6b"');
$pi-knife-line: unquote('"\\ed6c"');
$pi-layer-line: unquote('"\\ed6d"');
$pi-layout-2-line: unquote('"\\ed6e"');
$pi-layout-3-line: unquote('"\\ed6f"');
$pi-layout-4-line: unquote('"\\ed70"');
$pi-layout-5-line: unquote('"\\ed71"');
$pi-layout-6-line: unquote('"\\ed72"');
$pi-layout-7-line: unquote('"\\ed73"');
$pi-layout-8-line: unquote('"\\ed74"');
$pi-layout-9-line: unquote('"\\ed75"');
$pi-layout-10-line: unquote('"\\ed76"');
$pi-layout-11-line: unquote('"\\ed77"');
$pi-layout-bottom-close-line: unquote('"\\ed78"');
$pi-layout-bottom-line: unquote('"\\ed79"');
$pi-layout-bottom-open-line: unquote('"\\ed7a"');
$pi-layout-grid-line: unquote('"\\ed7b"');
$pi-layout-left-line: unquote('"\\ed7c"');
$pi-layout-leftbar-close-line: unquote('"\\ed7d"');
$pi-layout-leftbar-open-line: unquote('"\\ed7e"');
$pi-layout-line: unquote('"\\ed7f"');
$pi-layout-right-line: unquote('"\\ed80"');
$pi-layout-rightbar-close-line: unquote('"\\ed81"');
$pi-layout-rightbar-open-line: unquote('"\\ed82"');
$pi-layout-top-close-line: unquote('"\\ed83"');
$pi-layout-top-line: unquote('"\\ed84"');
$pi-layout-top-open-line: unquote('"\\ed85"');
$pi-magic-1-line: unquote('"\\ed86"');
$pi-magic-2-line: unquote('"\\ed87"');
$pi-magic-3-line: unquote('"\\ed88"');
$pi-magic-hat-2-line: unquote('"\\ed89"');
$pi-magic-hat-line: unquote('"\\ed8a"');
$pi-mark-pen-line: unquote('"\\ed8b"');
$pi-markup-line: unquote('"\\ed8c"');
$pi-mirror-line: unquote('"\\ed8d"');
$pi-mosaic-line: unquote('"\\ed8e"');
$pi-paint-2-line: unquote('"\\ed8f"');
$pi-paint-brush-line: unquote('"\\ed90"');
$pi-palette-2-line: unquote('"\\ed91"');
$pi-palette-line: unquote('"\\ed92"');
$pi-paster-line: unquote('"\\ed93"');
$pi-pen-line: unquote('"\\ed94"');
$pi-quill-pen-line: unquote('"\\ed95"');
$pi-rotate-x-line: unquote('"\\ed96"');
$pi-rotate-y-line: unquote('"\\ed97"');
$pi-rows-2-line: unquote('"\\ed98"');
$pi-rows-3-line: unquote('"\\ed99"');
$pi-rows-4-line: unquote('"\\ed9a"');
$pi-ruler-line: unquote('"\\ed9b"');
$pi-scale-line: unquote('"\\ed9c"');
$pi-scissors-2-line: unquote('"\\ed9d"');
$pi-scissors-3-line: unquote('"\\ed9e"');
$pi-avalanche-avax-line: unquote('"\\ed9f"');
$pi-binance-coin-bnb-line: unquote('"\\eda0"');
$pi-binance-usd-busd-line: unquote('"\\eda1"');
$pi-bnb-line: unquote('"\\eda2"');
$pi-cardano-ada-line: unquote('"\\eda3"');
$pi-currency-bitcoin-2-line: unquote('"\\eda4"');
$pi-currency-bitcoin-line: unquote('"\\eda5"');
$pi-dogecoin-doge-line: unquote('"\\eda6"');
$pi-ethereum-line: unquote('"\\eda7"');
$pi-exchange-bitcoin-line: unquote('"\\eda8"');
$pi-monero-line: unquote('"\\eda9"');
$pi-polkadot-dot-line: unquote('"\\edaa"');
$pi-solana-sol-line: unquote('"\\edab"');
$pi-tether-usdt-line: unquote('"\\edac"');
$pi-usd-coin-usdc-line: unquote('"\\edad"');
$pi-xrp-line: unquote('"\\edae"');
$pi-chat-1-line: unquote('"\\edaf"');
$pi-chat-2-line: unquote('"\\edb0"');
$pi-chat-3-line: unquote('"\\edb1"');
$pi-chat-4-line: unquote('"\\edb2"');
$pi-comment-2-line: unquote('"\\edb3"');
$pi-comment-line: unquote('"\\edb4"');
$pi-invite-line: unquote('"\\edb5"');
$pi-mail-line: unquote('"\\edb6"');
$pi-mail-open-line: unquote('"\\edb7"');
$pi-mail-send-line: unquote('"\\edb8"');
$pi-mailbox-line: unquote('"\\edb9"');
$pi-message-1-line: unquote('"\\edba"');
$pi-message-2-line: unquote('"\\edbb"');
$pi-message-3-line: unquote('"\\edbc"');
$pi-message-4-line: unquote('"\\edbd"');
$pi-phone-add-line: unquote('"\\edbe"');
$pi-phone-block-line: unquote('"\\edbf"');
$pi-phone-call-line: unquote('"\\edc0"');
$pi-phone-incoming-line: unquote('"\\edc1"');
$pi-phone-line: unquote('"\\edc2"');
$pi-phone-off-line: unquote('"\\edc3"');
$pi-phone-outgoing-line: unquote('"\\edc4"');
$pi-phone-success-line: unquote('"\\edc5"');
$pi-send-line: unquote('"\\edc6"');
$pi-send-plane-line: unquote('"\\edc7"');
$pi-shopping-bag-1-line: unquote('"\\edc8"');
$pi-shopping-bag-2-line: unquote('"\\edc9"');
$pi-shopping-bag-3-line: unquote('"\\edca"');
$pi-shopping-cart-1-line: unquote('"\\edcb"');
$pi-shopping-cart-2-line: unquote('"\\edcc"');
$pi-sofa-line: unquote('"\\edcd"');
$pi-stock-line: unquote('"\\edce"');
$pi-suitcase-2-line: unquote('"\\edcf"');
$pi-suitcase-line: unquote('"\\edd0"');
$pi-tag-2-line: unquote('"\\edd1"');
$pi-tag-chevron-line: unquote('"\\edd2"');
$pi-tag-line: unquote('"\\edd3"');
$pi-target-line: unquote('"\\edd4"');
$pi-ticket-line: unquote('"\\edd5"');
$pi-trophy-line: unquote('"\\edd6"');
$pi-vip-1-line: unquote('"\\edd7"');
$pi-vip-2-line: unquote('"\\edd8"');
$pi-vip-3-line: unquote('"\\edd9"');
$pi-vip-4-line: unquote('"\\edda"');
$pi-wallet-2-line: unquote('"\\eddb"');
$pi-wallet-3-line: unquote('"\\eddc"');
$pi-wallet-4-line: unquote('"\\eddd"');
$pi-wallet-5-line: unquote('"\\edde"');
$pi-wallet-line: unquote('"\\eddf"');
$pi-yuanbao-line: unquote('"\\ede0"');
$pi-ad-circle-line: unquote('"\\ede1"');
$pi-ad-circle-off-line: unquote('"\\ede2"');
$pi-ad-rectangle-line: unquote('"\\ede3"');
$pi-ad-rectangle-off-line: unquote('"\\ede4"');
$pi-anniversary-line: unquote('"\\ede5"');
$pi-auction-line: unquote('"\\ede6"');
$pi-award-line: unquote('"\\ede7"');
$pi-bank-card-line: unquote('"\\ede8"');
$pi-basket-2-line: unquote('"\\ede9"');
$pi-basket-line: unquote('"\\edea"');
$pi-briefcase-line: unquote('"\\edeb"');
$pi-calendar-2-line: unquote('"\\edec"');
$pi-calendar-3-line: unquote('"\\eded"');
$pi-calendar-add-line: unquote('"\\edee"');
$pi-calendar-day-line: unquote('"\\edef"');
$pi-calendar-line: unquote('"\\edf0"');
$pi-calendar-month-line: unquote('"\\edf1"');
$pi-calendar-Time-add-line: unquote('"\\edf2"');
$pi-calendar-Week-line: unquote('"\\edf3"');
$pi-candle-line: unquote('"\\edf4"');
$pi-candles-line: unquote('"\\edf5"');
$pi-card-pay-line: unquote('"\\edf6"');
$pi-card-refund-line: unquote('"\\edf7"');
$pi-cash-line: unquote('"\\edf8"');
$pi-celebrate-line: unquote('"\\edf9"');
$pi-chart-bar-2-line: unquote('"\\edfa"');
$pi-chart-bar-line: unquote('"\\edfb"');
$pi-chart-decrease-line: unquote('"\\edfc"');
$pi-chart-Horizontal-line: unquote('"\\edfd"');
$pi-chart-line-line: unquote('"\\edfe"');
$pi-chart-pie-2-line: unquote('"\\edff"');
$pi-chart-pie-line: unquote('"\\ee00"');
$pi-chart-Vertical-line: unquote('"\\ee01"');
$pi-chines-Knot-line: unquote('"\\ee02"');
$pi-christmas-ball-line: unquote('"\\ee03"');
$pi-coin-2-line: unquote('"\\ee04"');
$pi-coin-3-line: unquote('"\\ee05"');
$pi-coin-line: unquote('"\\ee06"');
$pi-copper-coin-line: unquote('"\\ee07"');
$pi-copyright-line: unquote('"\\ee08"');
$pi-coupon-line: unquote('"\\ee09"');
$pi-currency-baht-2-line: unquote('"\\ee0a"');
$pi-currency-baht-line: unquote('"\\ee0b"');
$pi-currency-cny-2-line: unquote('"\\ee0c"');
$pi-currency-cny-line: unquote('"\\ee0d"');
$pi-currency-dollar-2-line: unquote('"\\ee0e"');
$pi-currency-dollar-line: unquote('"\\ee0f"');
$pi-currency-euro-2-line: unquote('"\\ee10"');
$pi-currency-euro-line: unquote('"\\ee11"');
$pi-currency-lira-2-line: unquote('"\\ee12"');
$pi-currency-lira-line: unquote('"\\ee13"');
$pi-currency-Nigeria-2-line: unquote('"\\ee14"');
$pi-currency-Nigeria-line: unquote('"\\ee15"');
$pi-currency-pound-2-line: unquote('"\\ee16"');
$pi-currency-pound-line: unquote('"\\ee17"');
$pi-currency-rubel-2-line: unquote('"\\ee18"');
$pi-currency-rubel-line: unquote('"\\ee19"');
$pi-currency-rupee-2-line: unquote('"\\ee1a"');
$pi-currency-rupee-line: unquote('"\\ee1b"');
$pi-currency-shekel-2-line: unquote('"\\ee1c"');
$pi-currency-shekel-line: unquote('"\\ee1d"');
$pi-currency-Won-2-line: unquote('"\\ee1e"');
$pi-currency-Won-line: unquote('"\\ee1f"');
$pi-diamond-2-line: unquote('"\\ee20"');
$pi-exchange-baht-line: unquote('"\\ee21"');
$pi-exchange-cny-line: unquote('"\\ee22"');
$pi-exchange-dollar-line: unquote('"\\ee23"');
$pi-exchange-euro-line: unquote('"\\ee24"');
$pi-filter-3-line: unquote('"\\ee25"');
$pi-filter-line: unquote('"\\ee26"');
$pi-flag-1-line: unquote('"\\ee27"');
$pi-flag-2-line: unquote('"\\ee28"');
$pi-flag-3-line: unquote('"\\ee29"');
$pi-flag-4-line: unquote('"\\ee2a"');
$pi-gift-2-line: unquote('"\\ee2b"');
$pi-gift-card-line: unquote('"\\ee2c"');
$pi-gift-line: unquote('"\\ee2d"');
$pi-ladder-line: unquote('"\\ee2e"');
$pi-lantern-2-line: unquote('"\\ee2f"');
$pi-lantern-line: unquote('"\\ee30"');
$pi-luggage-line: unquote('"\\ee31"');
$pi-medal-line: unquote('"\\ee32"');
$pi-palette-3-line: unquote('"\\ee33"');
$pi-pig-money-line: unquote('"\\ee34"');
$pi-presentation-1-line: unquote('"\\ee35"');
$pi-presentation-2-line: unquote('"\\ee36"');
$pi-presentation-3-line: unquote('"\\ee37"');
$pi-pumpkin-lantern-line: unquote('"\\ee38"');
$pi-receive-money-line: unquote('"\\ee39"');
$pi-red-packet-line: unquote('"\\ee3a"');
$pi-red-packet-open-line: unquote('"\\ee3b"');
$pi-refund-cny-line: unquote('"\\ee3c"');
$pi-refund-dollar-line: unquote('"\\ee3d"');
$pi-registered-line: unquote('"\\ee3e"');
$pi-safe-box-line: unquote('"\\ee3f"');
$pi-sale-line: unquote('"\\ee40"');
$pi-schedule-line: unquote('"\\ee41"');
$pi-seal-line: unquote('"\\ee42"');
$pi-shopping-bag-1-line1: unquote('"\\ee43"');
$pi-shopping-bag-2-line1: unquote('"\\ee44"');
$pi-bank-line: unquote('"\\ee45"');
$pi-bank-of-china-tower-line: unquote('"\\ee46"');
$pi-big-ben-line: unquote('"\\ee47"');
$pi-bridge-2-line: unquote('"\\ee48"');
$pi-bridge-line: unquote('"\\ee49"');
$pi-building-1-line: unquote('"\\ee4a"');
$pi-building-2-line: unquote('"\\ee4b"');
$pi-building-3-line: unquote('"\\ee4c"');
$pi-building-4-line: unquote('"\\ee4d"');
$pi-building-5-line: unquote('"\\ee4e"');
$pi-building-6-line: unquote('"\\ee4f"');
$pi-burj-al-arab-line: unquote('"\\ee50"');
$pi-burj-khalifa-tower-line: unquote('"\\ee51"');
$pi-campground-line: unquote('"\\ee52"');
$pi-canton-tower-line: unquote('"\\ee53"');
$pi-christ-the-Redeemer-line: unquote('"\\ee54"');
$pi-church-line: unquote('"\\ee55"');
$pi-dutch-Windmill-line: unquote('"\\ee56"');
$pi-egyptian-pyramids-line: unquote('"\\ee57"');
$pi-eiffel-tower-line: unquote('"\\ee58"');
$pi-factory-2-line: unquote('"\\ee59"');
$pi-factory-line: unquote('"\\ee5a"');
$pi-ferris-Wheel-line: unquote('"\\ee5b"');
$pi-government-line: unquote('"\\ee5c"');
$pi-greatwall-line: unquote('"\\ee5d"');
$pi-home-1-line: unquote('"\\ee5e"');
$pi-home-2-line: unquote('"\\ee5f"');
$pi-home-3-line: unquote('"\\ee60"');
$pi-home-4-line: unquote('"\\ee61"');
$pi-home-5-line: unquote('"\\ee62"');
$pi-home-6-line: unquote('"\\ee63"');
$pi-home-7-line: unquote('"\\ee64"');
$pi-hospital-line: unquote('"\\ee65"');
$pi-hotel-line: unquote('"\\ee66"');
$pi-house-2-line: unquote('"\\ee67"');
$pi-house-line: unquote('"\\ee68"');
$pi-kingkey-100-tower-line: unquote('"\\ee69"');
$pi-lighthouse-line: unquote('"\\ee6a"');
$pi-marina-bay-sand-line: unquote('"\\ee6b"');
$pi-maya-pyramids-line: unquote('"\\ee6c"');
$pi-miyajima-torii-line: unquote('"\\ee6d"');
$pi-moai-line: unquote('"\\ee6e"');
$pi-monument-line: unquote('"\\ee6f"');
$pi-palace-line: unquote('"\\ee70"');
$pi-pavilion-line: unquote('"\\ee71"');
$pi-pisa-tower-line: unquote('"\\ee72"');
$pi-school-line: unquote('"\\ee73"');
$pi-shop-line: unquote('"\\ee74"');
$pi-statue-of-liberty-line: unquote('"\\ee75"');
$pi-store-2-line: unquote('"\\ee76"');
$pi-store-line: unquote('"\\ee77"');
$pi-sydney-opera-house-line: unquote('"\\ee78"');
$pi-taipei101-line: unquote('"\\ee79"');
$pi-taj-mahal-line: unquote('"\\ee7a"');
$pi-temple-of-heaven-line: unquote('"\\ee7b"');
$pi-tent-line: unquote('"\\ee7c"');
$pi-tower-line: unquote('"\\ee7d"');
$pi-triumphal-arch-line: unquote('"\\ee7e"');
$pi-tv-towe-line: unquote('"\\ee7f"');
$pi-align-arrow-down-line: unquote('"\\ee80"');
$pi-align-arrow-left-line: unquote('"\\ee81"');
$pi-align-arrow-right-line: unquote('"\\ee82"');
$pi-align-arrow-up-line: unquote('"\\ee83"');
$pi-arrow-down-Circle-line: unquote('"\\ee84"');
$pi-arrow-down-line: unquote('"\\ee85"');
$pi-arrow-left-Circle-line: unquote('"\\ee86"');
$pi-arrow-left-down-Circle-line: unquote('"\\ee87"');
$pi-arrow-left-down-line: unquote('"\\ee88"');
$pi-arrow-left-line: unquote('"\\ee89"');
$pi-arrow-left-up-Circle-line: unquote('"\\ee8a"');
$pi-arrow-left-up-line: unquote('"\\ee8b"');
$pi-arrow-right-Circle-line: unquote('"\\ee8c"');
$pi-arrow-right-down-Circle-line: unquote('"\\ee8d"');
$pi-arrow-right-down-line: unquote('"\\ee8e"');
$pi-arrow-right-line: unquote('"\\ee8f"');
$pi-arrow-right-up-Circle-line: unquote('"\\ee90"');
$pi-arrow-right-up-line: unquote('"\\ee91"');
$pi-arrow-to-down-line: unquote('"\\ee92"');
$pi-arrow-to-left-line: unquote('"\\ee93"');
$pi-arrow-to-right-line: unquote('"\\ee94"');
$pi-arrow-to-up-line: unquote('"\\ee95"');
$pi-arrow-up-Circle-line: unquote('"\\ee96"');
$pi-arrow-up-line: unquote('"\\ee97"');
$pi-arrows-down-line: unquote('"\\ee98"');
$pi-arrows-left-line: unquote('"\\ee99"');
$pi-arrows-right-line: unquote('"\\ee9a"');
$pi-arrows-up-line: unquote('"\\ee9b"');
$pi-back-2-line: unquote('"\\ee9c"');
$pi-back-line: unquote('"\\ee9d"');
$pi-direction-arrow-line: unquote('"\\ee9e"');
$pi-down-line: unquote('"\\ee9f"');
$pi-down-small-line: unquote('"\\eea0"');
$pi-forward-2-line: unquote('"\\eea1"');
$pi-forward-line: unquote('"\\eea2"');
$pi-fullscreen-2-line: unquote('"\\eea3"');
$pi-fullscreen-exit-2-line: unquote('"\\eea4"');
$pi-large-arrow-down-line: unquote('"\\eea5"');
$pi-large-arrow-left-line: unquote('"\\eea6"');
$pi-large-arrow-right-line: unquote('"\\eea7"');
$pi-large-arrow-up-line: unquote('"\\eea8"');
$pi-left-line: unquote('"\\eea9"');
$pi-left-small-line: unquote('"\\eeaa"');
$pi-move-line: unquote('"\\eeab"');
$pi-right-line: unquote('"\\eeac"');
$pi-right-small-line: unquote('"\\eead"');
$pi-selector-horizontal-line: unquote('"\\eeae"');
$pi-selector-Vertical-line: unquote('"\\eeaf"');
$pi-square-arrow-down-line: unquote('"\\eeb0"');
$pi-square-arrow-left-line: unquote('"\\eeb1"');
$pi-square-arrow-right-line: unquote('"\\eeb2"');
$pi-square-arrow-up-line: unquote('"\\eeb3"');
$pi-transfer-2-line: unquote('"\\eeb4"');
$pi-transfer-3-line: unquote('"\\eeb5"');
$pi-transfer-4-line: unquote('"\\eeb6"');
$pi-transfer-horizontal-line: unquote('"\\eeb7"');
$pi-transfer-line: unquote('"\\eeb8"');
$pi-transfer-Vertical-line: unquote('"\\eeb9"');
$pi-trending-down-line: unquote('"\\eeba"');
$pi-trending-up-line: unquote('"\\eebb"');
$pi-up-line: unquote('"\\eebc"');
$pi-up-small-line: unquote('"\\eebd"');

