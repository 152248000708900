.pi-hour-glass {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe979;');
}
.pi-spinner {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe97a;');
}
.pi-spinner2 {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe97b;');
}
.pi-spinner3 {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe97c;');
}
.pi-spinner4 {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe97d;');
}
.pi-spinner5 {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe97e;');
}
.pi-spinner6 {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe97f;');
}
.pi-spinner7 {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe980;');
}
.pi-spinner8 {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe981;');
}
.pi-spinner9 {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe982;');
}
.pi-spinner10 {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe983;');
}
.pi-add-circle-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe900;');
}
.pi-add-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe901;');
}
.pi-alert-diamond-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe902;');
}
.pi-alert-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe903;');
}
.pi-alert-octagon-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe904;');
}
.pi-broom-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe905;');
}
.pi-brush-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe906;');
}
.pi-brush-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe907;');
}
.pi-bubble-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe908;');
}
.pi-certificate-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe909;');
}
.pi-check-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe90a;');
}
.pi-check-circle-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe90b;');
}
.pi-check-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe90c;');
}
.pi-checkbox-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe90d;');
}
.pi-checks-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe90e;');
}
.pi-choice-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe90f;');
}
.pi-classify-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe910;');
}
.pi-classify-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe911;');
}
.pi-classify-add-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe912;');
}
.pi-classify-add-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe913;');
}
.pi-classify-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe914;');
}
.pi-close-circle-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe915;');
}
.pi-close-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe916;');
}
.pi-cross-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe917;');
}
.pi-crutch-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe918;');
}
.pi-cube-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe919;');
}
.pi-delete-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe91a;');
}
.pi-delete-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe91b;');
}
.pi-delete-back-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe91c;');
}
.pi-delete-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe91d;');
}
.pi-direction-dot-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe91e;');
}
.pi-door-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe91f;');
}
.pi-dot-grid-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe920;');
}
.pi-dots-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe921;');
}
.pi-dots-Vertical-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe922;');
}
.pi-enter-door-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe923;');
}
.pi-entrance-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe924;');
}
.pi-exit-door-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe925;');
}
.pi-exit-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe926;');
}
.pi-eye-close-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe927;');
}
.pi-eye-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe928;');
}
.pi-faceid-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe929;');
}
.pi-filter-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe92a;');
}
.pi-fingerprint-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe92b;');
}
.pi-fingerprint-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe92c;');
}
.pi-fire-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe92d;');
}
.pi-flame-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe92e;');
}
.pi-forbid-circle-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe92f;');
}
.pi-gesture-unlock-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe930;');
}
.pi-ghost-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe931;');
}
.pi-grid-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe932;');
}
.pi-grid-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe933;');
}
.pi-heartbeat-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe934;');
}
.pi-history-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe935;');
}
.pi-history-anticlockwise-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe936;');
}
.pi-history-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe937;');
}
.pi-information-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe938;');
}
.pi-key-1-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe939;');
}
.pi-key-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe93a;');
}
.pi-keyhole-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe93b;');
}
.pi-lock-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe93c;');
}
.pi-mind-map-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe93d;');
}
.pi-minimize-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe93e;');
}
.pi-minus-circle-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe93f;');
}
.pi-more-1-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe940;');
}
.pi-more-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe941;');
}
.pi-more-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe942;');
}
.pi-more-4-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe943;');
}
.pi-multiselect-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe944;');
}
.pi-newdot-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe945;');
}
.pi-open-door-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe946;');
}
.pi-plus-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe947;');
}
.pi-polygon-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe948;');
}
.pi-power-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe949;');
}
.pi-process-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe94a;');
}
.pi-question-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe94b;');
}
.pi-radiobox-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe94c;');
}
.pi-recycle-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe94d;');
}
.pi-refresh-1-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe94e;');
}
.pi-refresh-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe94f;');
}
.pi-refresh-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe950;');
}
.pi-refresh-4-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe951;');
}
.pi-refresh-anticlockwise-1-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe952;');
}
.pi-report-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe953;');
}
.pi-restore-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe954;');
}
.pi-safe-alert-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe955;');
}
.pi-safe-flash-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe956;');
}
.pi-safe-lock-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe957;');
}
.pi-safe-shield-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe958;');
}
.pi-safe-shield-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe959;');
}
.pi-safety-certificate-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe95a;');
}
.pi-settings-1-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe95b;');
}
.pi-settings-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe95c;');
}
.pi-settings-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe95d;');
}
.pi-settings-4-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe95e;');
}
.pi-settings-5-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe95f;');
}
.pi-settings-6-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe960;');
}
.pi-settings-7-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe961;');
}
.pi-share-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe962;');
}
.pi-share-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe963;');
}
.pi-air-balloon-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe964;');
}
.pi-american-football-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe965;');
}
.pi-backboard-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe966;');
}
.pi-badminton-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe967;');
}
.pi-barbell-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe968;');
}
.pi-baseball-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe969;');
}
.pi-baseball-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe96a;');
}
.pi-basketball-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe96b;');
}
.pi-beachball-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe96c;');
}
.pi-clubs-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe96d;');
}
.pi-cylinder-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe96e;');
}
.pi-diamond-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe96f;');
}
.pi-diamond-square-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe970;');
}
.pi-disabled-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe971;');
}
.pi-disabled-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe972;');
}
.pi-fitness-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe973;');
}
.pi-football-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe974;');
}
.pi-heart-crack-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe975;');
}
.pi-heart-half-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe976;');
}
.pi-heart-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe977;');
}
.pi-hexagon-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe978;');
}
.pi-line-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe984;');
}
.pi-octagon-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe985;');
}
.pi-parachute-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe986;');
}
.pi-pentagon-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe987;');
}
.pi-pingpong-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe988;');
}
.pi-playground-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe989;');
}
.pi-rectangle-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe98a;');
}
.pi-rectangle-vertical-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe98b;');
}
.pi-riding-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe98c;');
}
.pi-round-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe98d;');
}
.pi-run-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe98e;');
}
.pi-sector-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe98f;');
}
.pi-shield-shape-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe990;');
}
.pi-skateboard-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe991;');
}
.pi-spade-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe992;');
}
.pi-square-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe993;');
}
.pi-star-half-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe994;');
}
.pi-star-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe995;');
}
.pi-surfboard-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe996;');
}
.pi-swimming-pool-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe997;');
}
.pi-sword-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe998;');
}
.pi-toxophily-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe999;');
}
.pi-triangle-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe99a;');
}
.pi-volleyball-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe99b;');
}
.pi-walk-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe99c;');
}
.pi-wheelchair-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe99d;');
}
.pi-whistle-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe99e;');
}
.pi-baby-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe99f;');
}
.pi-beard-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9a0;');
}
.pi-body-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9a1;');
}
.pi-bow-tie-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9a2;');
}
.pi-bowknot-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9a3;');
}
.pi-brain-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9a4;');
}
.pi-brief-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9a5;');
}
.pi-chef-hat-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9a6;');
}
.pi-christmas-hat-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9a7;');
}
.pi-deer-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9a8;');
}
.pi-dental-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9a9;');
}
.pi-dress-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9aa;');
}
.pi-ear-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9ab;');
}
.pi-eye-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9ac;');
}
.pi-eyebrow-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9ad;');
}
.pi-face-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9ae;');
}
.pi-father-christmas-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9af;');
}
.pi-finger-press-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9b0;');
}
.pi-finger-Rock-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9b1;');
}
.pi-finger-swipe-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9b2;');
}
.pi-finger-tap-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9b3;');
}
.pi-glove-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9b4;');
}
.pi-hair-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9b5;');
}
.pi-hair-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9b6;');
}
.pi-hand-finger-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9b7;');
}
.pi-hand-finger-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9b8;');
}
.pi-hand-grab-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9b9;');
}
.pi-hand-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9ba;');
}
.pi-hand-two-fingers-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9bb;');
}
.pi-hands-clapping-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9bc;');
}
.pi-hat-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9bd;');
}
.pi-hat-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9be;');
}
.pi-head-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9bf;');
}
.pi-heart-hand-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9c0;');
}
.pi-heartbeat-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9c1;');
}
.pi-incognito-mode-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9c2;');
}
.pi-lungs-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9c3;');
}
.pi-middle-finger-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9c4;');
}
.pi-mouth-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9c5;');
}
.pi-necktie-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9c6;');
}
.pi-nose-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9c7;');
}
.pi-pray-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9c8;');
}
.pi-scarf-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9c9;');
}
.pi-shirt-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9ca;');
}
.pi-shoe-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9cb;');
}
.pi-shoe-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9cc;');
}
.pi-shorts-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9cd;');
}
.pi-skirt-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9ce;');
}
.pi-skull-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9cf;');
}
.pi-sock-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9d0;');
}
.pi-t-shirt-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9d1;');
}
.pi-trouser-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9d2;');
}
.pi-vest-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9d3;');
}
.pi-celsius-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9d4;');
}
.pi-cloud-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9d5;');
}
.pi-cloud-lightning-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9d6;');
}
.pi-cloud-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9d7;');
}
.pi-cloud-snow-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9d8;');
}
.pi-cloud-windy-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9d9;');
}
.pi-clouds-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9da;');
}
.pi-drizzle-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9db;');
}
.pi-dry-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9dc;');
}
.pi-fahrenheit-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9dd;');
}
.pi-floating-dust-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9de;');
}
.pi-fog-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9df;');
}
.pi-full-moon-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9e0;');
}
.pi-hail-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9e1;');
}
.pi-haze-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9e2;');
}
.pi-heavy-rain-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9e3;');
}
.pi-heavy-rainstorm-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9e4;');
}
.pi-heavy-snow-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9e5;');
}
.pi-heavy-snowstorm-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9e6;');
}
.pi-high-temperature-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9e7;');
}
.pi-light-snow-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9e8;');
}
.pi-lightning-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9e9;');
}
.pi-low-temperature-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9ea;');
}
.pi-moderate-snow-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9eb;');
}
.pi-moon-cloudy-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9ec;');
}
.pi-moon-fog-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9ed;');
}
.pi-moon-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9ee;');
}
.pi-moon-stars-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9ef;');
}
.pi-moonlight-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9f0;');
}
.pi-na-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9f1;');
}
.pi-partly-cloud-daytime-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9f2;');
}
.pi-partly-cloud-night-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9f3;');
}
.pi-rain-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9f4;');
}
.pi-rainbow-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9f5;');
}
.pi-rainstorm-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9f6;');
}
.pi-sandstorm-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9f7;');
}
.pi-showers-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9f8;');
}
.pi-snow-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9f9;');
}
.pi-snowflake-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9fa;');
}
.pi-snowman-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9fb;');
}
.pi-snowstorm-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9fc;');
}
.pi-snowstorm-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9fd;');
}
.pi-sparkles-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9fe;');
}
.pi-sparkles-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xe9ff;');
}
.pi-sun-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea00;');
}
.pi-sun-cloudy-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea01;');
}
.pi-sun-fog-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea02;');
}
.pi-sun-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea03;');
}
.pi-sunrise-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea04;');
}
.pi-sunset-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea05;');
}
.pi-thermometer-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea06;');
}
.pi-thunderstorm-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea07;');
}
.pi-tornado-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea08;');
}
.pi-tornado-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea09;');
}
.pi-typhoon-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea0a;');
}
.pi-umbrella-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea0b;');
}
.pi-user-4-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea0c;');
}
.pi-user-5-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea0d;');
}
.pi-user-add-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea0e;');
}
.pi-user-add-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea0f;');
}
.pi-user-edit-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea10;');
}
.pi-user-follow-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea11;');
}
.pi-user-follow-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea12;');
}
.pi-user-forbid-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea13;');
}
.pi-user-heart-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea14;');
}
.pi-user-hide-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea15;');
}
.pi-user-info-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea16;');
}
.pi-user-lock-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea17;');
}
.pi-user-pin-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea18;');
}
.pi-user-question-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea19;');
}
.pi-user-remove-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea1a;');
}
.pi-user-remove-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea1b;');
}
.pi-user-search-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea1c;');
}
.pi-user-security-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea1d;');
}
.pi-user-setting-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea1e;');
}
.pi-user-star-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea1f;');
}
.pi-user-visible-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea20;');
}
.pi-user-warning-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea21;');
}
.pi-user-x-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea22;');
}
.pi-wave-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea23;');
}
.pi-wet-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea24;');
}
.pi-wind-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea25;');
}
.pi-axe-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea26;');
}
.pi-balloon-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea27;');
}
.pi-bath-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea28;');
}
.pi-bedside-table-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea29;');
}
.pi-bedside-table-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea2a;');
}
.pi-blessing-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea2b;');
}
.pi-bomb-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea2c;');
}
.pi-boom-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea2d;');
}
.pi-bottle-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea2e;');
}
.pi-capsule-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea2f;');
}
.pi-coathanger-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea30;');
}
.pi-cross-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea31;');
}
.pi-crystal-ball-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea32;');
}
.pi-curtain-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea33;');
}
.pi-drawer-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea34;');
}
.pi-drawer-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea35;');
}
.pi-face-mask-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea36;');
}
.pi-firecracker-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea37;');
}
.pi-firework-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea38;');
}
.pi-first-aid-kit-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea39;');
}
.pi-hammer-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea3a;');
}
.pi-hand-card-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea3b;');
}
.pi-hand-heart-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea3c;');
}
.pi-hoe-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea3d;');
}
.pi-injection-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea3e;');
}
.pi-kite-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea3f;');
}
.pi-nurse-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea40;');
}
.pi-pickax-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea41;');
}
.pi-pot-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea42;');
}
.pi-prescription-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea43;');
}
.pi-rake-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea44;');
}
.pi-random-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea45;');
}
.pi-screwdriver-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea46;');
}
.pi-shovel-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea47;');
}
.pi-shower-gel-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea48;');
}
.pi-star-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea49;');
}
.pi-star-topper-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea4a;');
}
.pi-stethoscope-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea4b;');
}
.pi-thought-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea4c;');
}
.pi-toilet-paper-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea4d;');
}
.pi-virus-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea4e;');
}
.pi-wardrobe-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea4f;');
}
.pi-wardrobe-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea50;');
}
.pi-yinyang-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea51;');
}
.pi-abs-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea52;');
}
.pi-aerial-lift-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea53;');
}
.pi-airplane-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea54;');
}
.pi-auto-hold-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea55;');
}
.pi-baby-carriage-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea56;');
}
.pi-badge-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea57;');
}
.pi-bike-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea58;');
}
.pi-brake-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea59;');
}
.pi-bus-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea5a;');
}
.pi-bus-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea5b;');
}
.pi-car-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea5c;');
}
.pi-car-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea5d;');
}
.pi-car-door-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea5e;');
}
.pi-car-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea5f;');
}
.pi-car-window-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea60;');
}
.pi-charging-pile-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea61;');
}
.pi-contacts-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea62;');
}
.pi-contacts-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea63;');
}
.pi-contacts-4-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea64;');
}
.pi-contacts-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea65;');
}
.pi-ebike-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea66;');
}
.pi-emergency-flashers-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea67;');
}
.pi-engine-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea68;');
}
.pi-fan-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea69;');
}
.pi-fan-direction-down-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea6a;');
}
.pi-fan-direction-front-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea6b;');
}
.pi-fan-direction-up-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea6c;');
}
.pi-fan-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea6d;');
}
.pi-female-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea6e;');
}
.pi-flight-inflight-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea6f;');
}
.pi-flight-land-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea70;');
}
.pi-flight-takeoff-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea71;');
}
.pi-four-wheel-drive-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea72;');
}
.pi-front-fog-lights-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea73;');
}
.pi-front-windshield-defroster-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea74;');
}
.pi-gas-station-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea75;');
}
.pi-group-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea76;');
}
.pi-group-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea77;');
}
.pi-group-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea78;');
}
.pi-hight-beam-headlights-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea79;');
}
.pi-hood-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea7a;');
}
.pi-idcard-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea7b;');
}
.pi-jeep-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea7c;');
}
.pi-low-beam-headlights-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea7d;');
}
.pi-male-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea7e;');
}
.pi-oil-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea7f;');
}
.pi-park-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea80;');
}
.pi-parking-lights-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea81;');
}
.pi-pinwheel-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea82;');
}
.pi-pinwheel-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea83;');
}
.pi-rare-fog-lights-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea84;');
}
.pi-rear-windshield-defroster-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea85;');
}
.pi-rocket-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea86;');
}
.pi-rocket-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea87;');
}
.pi-rudder-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea88;');
}
.pi-sailboat-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea89;');
}
.pi-scooter-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea8a;');
}
.pi-seat-heated-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea8b;');
}
.pi-seat-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea8c;');
}
.pi-share-forward-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea8d;');
}
.pi-shield-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea8e;');
}
.pi-ship-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea8f;');
}
.pi-sleep-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea90;');
}
.pi-sleigh-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea91;');
}
.pi-steering-wheel-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea92;');
}
.pi-switch-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea93;');
}
.pi-t-shirt-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea94;');
}
.pi-tank-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea95;');
}
.pi-three-circles-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea96;');
}
.pi-thumb-down-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea97;');
}
.pi-thumb-down-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea98;');
}
.pi-thumb-up-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea99;');
}
.pi-thumb-up-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea9a;');
}
.pi-time-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea9b;');
}
.pi-toggle-left-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea9c;');
}
.pi-toggle-left-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea9d;');
}
.pi-toggle-right-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea9e;');
}
.pi-toggle-Right-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xea9f;');
}
.pi-tool-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeaa0;');
}
.pi-traffic-lights-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeaa1;');
}
.pi-train-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeaa2;');
}
.pi-train-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeaa3;');
}
.pi-train-4-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeaa4;');
}
.pi-train-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeaa5;');
}
.pi-transformation-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeaa6;');
}
.pi-truck-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeaa7;');
}
.pi-trunk-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeaa8;');
}
.pi-tyre-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeaa9;');
}
.pi-ufo-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeaaa;');
}
.pi-ufo-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeaab;');
}
.pi-unlock-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeaac;');
}
.pi-user-1-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeaad;');
}
.pi-user-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeaae;');
}
.pi-user-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeaaf;');
}
.pi-user-4-line1 {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeab0;');
}
.pi-version-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeab1;');
}
.pi-warning-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeab2;');
}
.pi-wastebasket-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeab3;');
}
.pi-wheel-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeab4;');
}
.pi-wiper-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeab5;');
}
.pi-bear-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeab6;');
}
.pi-bird-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeab7;');
}
.pi-butterfly-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeab8;');
}
.pi-butterfly-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeab9;');
}
.pi-cactus-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeaba;');
}
.pi-cactus-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeabb;');
}
.pi-campfire-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeabc;');
}
.pi-cat-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeabd;');
}
.pi-chess-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeabe;');
}
.pi-dandelion-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeabf;');
}
.pi-dog-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeac0;');
}
.pi-dragonfly-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeac1;');
}
.pi-flower-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeac2;');
}
.pi-flower-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeac3;');
}
.pi-flower-4-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeac4;');
}
.pi-flower-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeac5;');
}
.pi-flowerpot-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeac6;');
}
.pi-grass-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeac7;');
}
.pi-leaf-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeac8;');
}
.pi-leaf-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeac9;');
}
.pi-leaf-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeaca;');
}
.pi-lotus-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeacb;');
}
.pi-maple-leaf-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeacc;');
}
.pi-mickeymouse-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeacd;');
}
.pi-mountain-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeace;');
}
.pi-mountain-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeacf;');
}
.pi-mushroom-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xead0;');
}
.pi-paw-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xead1;');
}
.pi-pig-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xead2;');
}
.pi-pumpkin-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xead3;');
}
.pi-rabbit-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xead4;');
}
.pi-sunflower-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xead5;');
}
.pi-toy-horse-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xead6;');
}
.pi-tree-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xead7;');
}
.pi-tree-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xead8;');
}
.pi-tree-4-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xead9;');
}
.pi-tree-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeada;');
}
.pi-wreath-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeadb;');
}
.pi-airpods-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeadc;');
}
.pi-album-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeadd;');
}
.pi-announcement-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeade;');
}
.pi-audio-tape-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeadf;');
}
.pi-bell-ringing-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeae0;');
}
.pi-camcorder-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeae1;');
}
.pi-camcorder-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeae2;');
}
.pi-camcorder-off-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeae3;');
}
.pi-camera-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeae4;');
}
.pi-camera-2-off-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeae5;');
}
.pi-camera-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeae6;');
}
.pi-camera-rotate-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeae7;');
}
.pi-clapperboard-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeae8;');
}
.pi-danmaku-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeae9;');
}
.pi-danmaku-off-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeaea;');
}
.pi-danmaku-on-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeaeb;');
}
.pi-disc-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeaec;');
}
.pi-expand-player-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeaed;');
}
.pi-fast-forward-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeaee;');
}
.pi-fast-rewind-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeaef;');
}
.pi-film-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeaf0;');
}
.pi-fullscreen-exit-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeaf1;');
}
.pi-fullscreen-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeaf2;');
}
.pi-headphone-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeaf3;');
}
.pi-headphone-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeaf4;');
}
.pi-horn-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeaf5;');
}
.pi-horn-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeaf6;');
}
.pi-live-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeaf7;');
}
.pi-live-photo-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeaf8;');
}
.pi-mic-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeaf9;');
}
.pi-mic-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeafa;');
}
.pi-mic-off-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeafb;');
}
.pi-microphone-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeafc;');
}
.pi-miniplayer-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeafd;');
}
.pi-movie-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeafe;');
}
.pi-music-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeaff;');
}
.pi-music-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb00;');
}
.pi-music-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb01;');
}
.pi-notification-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb02;');
}
.pi-notification-newdot-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb03;');
}
.pi-notification-off-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb04;');
}
.pi-pause-circle-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb05;');
}
.pi-pause-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb06;');
}
.pi-play-circle-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb07;');
}
.pi-play-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb08;');
}
.pi-playlist-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb09;');
}
.pi-playlist-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb0a;');
}
.pi-record-mail-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb0b;');
}
.pi-repeat-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb0c;');
}
.pi-repeat-one-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb0d;');
}
.pi-rotate-to-horizontal-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb0e;');
}
.pi-rotate-to-vertical-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb0f;');
}
.pi-service-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb10;');
}
.pi-shuffle-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb11;');
}
.pi-shuffle-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb12;');
}
.pi-skip-forward-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb13;');
}
.pi-skip-previous-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb14;');
}
.pi-sound-line-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb15;');
}
.pi-stop-circle-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb16;');
}
.pi-stop-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb17;');
}
.pi-video-camera-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb18;');
}
.pi-video-camera-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb19;');
}
.pi-video-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb1a;');
}
.pi-voice-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb1b;');
}
.pi-voice-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb1c;');
}
.pi-volume-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb1d;');
}
.pi-volume-mute-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb1e;');
}
.pi-volume-off-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb1f;');
}
.pi-aiming-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb20;');
}
.pi-aiming-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb21;');
}
.pi-anchor-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb22;');
}
.pi-bed-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb23;');
}
.pi-bed-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb24;');
}
.pi-clock-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb25;');
}
.pi-clock-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb26;');
}
.pi-compass-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb27;');
}
.pi-compass-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb28;');
}
.pi-directions-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb29;');
}
.pi-directions-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb2a;');
}
.pi-earth-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb2b;');
}
.pi-earth-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb2c;');
}
.pi-earth-latitude-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb2d;');
}
.pi-earth-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb2e;');
}
.pi-earth-longitude-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb2f;');
}
.pi-foot-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb30;');
}
.pi-hours-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb31;');
}
.pi-lifebuoy-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb32;');
}
.pi-live-location-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb33;');
}
.pi-location-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb34;');
}
.pi-location-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb35;');
}
.pi-location-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb36;');
}
.pi-map-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb37;');
}
.pi-map-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb38;');
}
.pi-map-pin-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb39;');
}
.pi-navigation-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb3a;');
}
.pi-parking-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb3b;');
}
.pi-planet-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb3c;');
}
.pi-rest-area-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb3d;');
}
.pi-road-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb3e;');
}
.pi-route-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb3f;');
}
.pi-traffic-cone-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb40;');
}
.pi-tunnel-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb41;');
}
.pi-umbrella-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb42;');
}
.pi-world-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb43;');
}
.pi-world-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb44;');
}
.pi-airbnb-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb45;');
}
.pi-airdrop-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb46;');
}
.pi-alipay-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb47;');
}
.pi-android-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb48;');
}
.pi-android-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb49;');
}
.pi-apple-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb4a;');
}
.pi-appstore-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb4b;');
}
.pi-arc-browser-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb4c;');
}
.pi-behance-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb4d;');
}
.pi-bluesky-social-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb4e;');
}
.pi-carplay-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb4f;');
}
.pi-chrome-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb50;');
}
.pi-codepen-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb51;');
}
.pi-dingtalk-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb52;');
}
.pi-discord-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb53;');
}
.pi-dribbble-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb54;');
}
.pi-drive-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb55;');
}
.pi-dropbox-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb56;');
}
.pi-edge-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb57;');
}
.pi-facebook-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb58;');
}
.pi-figma-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb59;');
}
.pi-firefox-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb5a;');
}
.pi-git-lab-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb5b;');
}
.pi-github-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb5c;');
}
.pi-github-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb5d;');
}
.pi-google-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb5e;');
}
.pi-google-play-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb5f;');
}
.pi-grok-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb60;');
}
.pi-gumroad-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb61;');
}
.pi-ins-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb62;');
}
.pi-kakao-talk-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb63;');
}
.pi-lemon-squeezy-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb64;');
}
.pi-line-app-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb65;');
}
.pi-linear-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb66;');
}
.pi-linkedin-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb67;');
}
.pi-linux-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb68;');
}
.pi-mastercard-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb69;');
}
.pi-mastodon-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb6a;');
}
.pi-medium-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb6b;');
}
.pi-messenger-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb6c;');
}
.pi-meta-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb6d;');
}
.pi-mingcute-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb6e;');
}
.pi-moment-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb6f;');
}
.pi-nfc-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb70;');
}
.pi-nintendo-switch-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb71;');
}
.pi-notion-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb72;');
}
.pi-openai-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb73;');
}
.pi-paypal-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb74;');
}
.pi-pinterest-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb75;');
}
.pi-qq-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb76;');
}
.pi-react-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb77;');
}
.pi-reddit-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb78;');
}
.pi-safari-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb79;');
}
.pi-snapchat-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb7a;');
}
.pi-social-x-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb7b;');
}
.pi-spotify-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb7c;');
}
.pi-stripe-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb7d;');
}
.pi-telegram-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb7e;');
}
.pi-threads-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb7f;');
}
.pi-tiktok-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb80;');
}
.pi-trello-board-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb81;');
}
.pi-twitter-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb82;');
}
.pi-viber-messenger-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb83;');
}
.pi-visa-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb84;');
}
.pi-vkontakte-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb85;');
}
.pi-vue-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb86;');
}
.pi-wechat-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb87;');
}
.pi-wechat-miniprogram-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb88;');
}
.pi-wechat-pay-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb89;');
}
.pi-weibo-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb8a;');
}
.pi-whatsapp-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb8b;');
}
.pi-windows-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb8c;');
}
.pi-xbox-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb8d;');
}
.pi-youtube-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb8e;');
}
.pi-birthday-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb8f;');
}
.pi-bone-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb90;');
}
.pi-bottle-glass-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb91;');
}
.pi-bowl-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb92;');
}
.pi-bread-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb93;');
}
.pi-cake-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb94;');
}
.pi-candy-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb95;');
}
.pi-candy-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb96;');
}
.pi-carrot-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb97;');
}
.pi-champagne-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb98;');
}
.pi-chopsticks-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb99;');
}
.pi-cookie-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb9a;');
}
.pi-cookie-Man-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb9b;');
}
.pi-cupcake-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb9c;');
}
.pi-dinner-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb9d;');
}
.pi-dish-cover-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb9e;');
}
.pi-donut-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeb9f;');
}
.pi-drink-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeba0;');
}
.pi-egg-crack-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeba1;');
}
.pi-egg-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeba2;');
}
.pi-electric-cooker-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeba3;');
}
.pi-feeder-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeba4;');
}
.pi-fish-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeba5;');
}
.pi-fork-knife-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeba6;');
}
.pi-fork-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeba7;');
}
.pi-fork-spoon-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeba8;');
}
.pi-fried-egg-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeba9;');
}
.pi-fries-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebaa;');
}
.pi-glass-cup-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebab;');
}
.pi-hamburger-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebac;');
}
.pi-ice-cream-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebad;');
}
.pi-ice-cream-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebae;');
}
.pi-lollipop-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebaf;');
}
.pi-pizza-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebb0;');
}
.pi-soup-pot-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebb1;');
}
.pi-soup-pot-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebb2;');
}
.pi-spatula-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebb3;');
}
.pi-spoon-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebb4;');
}
.pi-sugar-coated-haws-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebb5;');
}
.pi-teacup-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebb6;');
}
.pi-wine-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebb7;');
}
.pi-wineglass-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebb8;');
}
.pi-wineglass-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebb9;');
}
.pi-xls-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebba;');
}
.pi-zoom-in-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebbb;');
}
.pi-zoom-out-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebbc;');
}
.pi-album-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebbd;');
}
.pi-archive-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebbe;');
}
.pi-attachment-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebbf;');
}
.pi-attachment-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebc0;');
}
.pi-bill-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebc1;');
}
.pi-bill-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebc2;');
}
.pi-box-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebc3;');
}
.pi-box-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebc4;');
}
.pi-box-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebc5;');
}
.pi-clipboard-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebc6;');
}
.pi-copy-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebc7;');
}
.pi-copy-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebc8;');
}
.pi-copy-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebc9;');
}
.pi-doc-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebca;');
}
.pi-document-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebcb;');
}
.pi-document-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebcc;');
}
.pi-document-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebcd;');
}
.pi-documents-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebce;');
}
.pi-download-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebcf;');
}
.pi-download-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebd0;');
}
.pi-download-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebd1;');
}
.pi-external-link-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebd2;');
}
.pi-file-certificate-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebd3;');
}
.pi-file-check-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebd4;');
}
.pi-file-code-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebd5;');
}
.pi-file-download-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebd6;');
}
.pi-file-export-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebd7;');
}
.pi-file-forbid-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebd8;');
}
.pi-file-Import-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebd9;');
}
.pi-file-Info-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebda;');
}
.pi-file-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebdb;');
}
.pi-file-locked-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebdc;');
}
.pi-file-more-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebdd;');
}
.pi-file-music-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebde;');
}
.pi-file-new-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebdf;');
}
.pi-file-search-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebe0;');
}
.pi-file-security-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebe1;');
}
.pi-file-star-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebe2;');
}
.pi-file-unknown-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebe3;');
}
.pi-file-upload-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebe4;');
}
.pi-file-warning-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebe5;');
}
.pi-file-zip-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebe6;');
}
.pi-folder-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebe7;');
}
.pi-folder-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebe8;');
}
.pi-folder-check-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebe9;');
}
.pi-folder-delete-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebea;');
}
.pi-folder-download-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebeb;');
}
.pi-folder-forbid-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebec;');
}
.pi-folder-Info-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebed;');
}
.pi-folder-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebee;');
}
.pi-folder-locked-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebef;');
}
.pi-folder-locked-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebf0;');
}
.pi-folder-minus-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebf1;');
}
.pi-folder-more-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebf2;');
}
.pi-folder-open-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebf3;');
}
.pi-folder-open-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebf4;');
}
.pi-folder-security-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebf5;');
}
.pi-folder-star-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebf6;');
}
.pi-folder-upload-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebf7;');
}
.pi-folder-warning-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebf8;');
}
.pi-folder-zip-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebf9;');
}
.pi-folders-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebfa;');
}
.pi-Inbox-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebfb;');
}
.pi-Inbox-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebfc;');
}
.pi-Inventory-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebfd;');
}
.pi-link-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebfe;');
}
.pi-link-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xebff;');
}
.pi-link-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec00;');
}
.pi-markdown-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec01;');
}
.pi-new-folder-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec02;');
}
.pi-news-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec03;');
}
.pi-news-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec04;');
}
.pi-package-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec05;');
}
.pi-package-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec06;');
}
.pi-paper-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec07;');
}
.pi-passport-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec08;');
}
.pi-pdf-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec09;');
}
.pi-photo-album-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec0a;');
}
.pi-photo-album-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec0b;');
}
.pi-pic-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec0c;');
}
.pi-pic-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec0d;');
}
.pi-pin-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec0e;');
}
.pi-pin-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec0f;');
}
.pi-ppt-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec10;');
}
.pi-profile-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec11;');
}
.pi-report-forms-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec12;');
}
.pi-save-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec13;');
}
.pi-save-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec14;');
}
.pi-search-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec15;');
}
.pi-search-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec16;');
}
.pi-search-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec17;');
}
.pi-task-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec18;');
}
.pi-task-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec19;');
}
.pi-to-do-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec1a;');
}
.pi-unarchive-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec1b;');
}
.pi-unlink-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec1c;');
}
.pi-unlink-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec1d;');
}
.pi-upload-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec1e;');
}
.pi-upload-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec1f;');
}
.pi-upload-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec20;');
}
.pi-angel-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec21;');
}
.pi-angry-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec22;');
}
.pi-confused-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec23;');
}
.pi-emoji-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec24;');
}
.pi-emoji-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec25;');
}
.pi-happy-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec26;');
}
.pi-look-down-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec27;');
}
.pi-look-left-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec28;');
}
.pi-look-right-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec29;');
}
.pi-look-up-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec2a;');
}
.pi-omg-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec2b;');
}
.pi-puzzled-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec2c;');
}
.pi-sad-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec2d;');
}
.pi-sick-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec2e;');
}
.pi-silent-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec2f;');
}
.pi-sob-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec30;');
}
.pi-surprise-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec31;');
}
.pi-sweats-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec32;');
}
.pi-terror-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec33;');
}
.pi-tongue-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec34;');
}
.pi-unhappy-dizzy-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec35;');
}
.pi-unhappy-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec36;');
}
.pi-backpack-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec37;');
}
.pi-balance-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec38;');
}
.pi-black-board-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec39;');
}
.pi-black-board-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec3a;');
}
.pi-book-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec3b;');
}
.pi-book-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec3c;');
}
.pi-book-4-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec3d;');
}
.pi-book-5-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec3e;');
}
.pi-book-6-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec3f;');
}
.pi-book-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec40;');
}
.pi-bookmark-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec41;');
}
.pi-bookmarks-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec42;');
}
.pi-certificate-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec43;');
}
.pi-compass-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec44;');
}
.pi-counter-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec45;');
}
.pi-desk-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec46;');
}
.pi-diary-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec47;');
}
.pi-drum-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec48;');
}
.pi-eyeglass-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec49;');
}
.pi-flask-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec4a;');
}
.pi-flask-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec4b;');
}
.pi-flask-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec4c;');
}
.pi-folding-fan-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec4d;');
}
.pi-globe-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec4e;');
}
.pi-magnet-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec4f;');
}
.pi-mortarboard-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec50;');
}
.pi-notebook-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec51;');
}
.pi-notebook-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec52;');
}
.pi-science-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec53;');
}
.pi-speech-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec54;');
}
.pi-test-tube-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec55;');
}
.pi-align-center-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec56;');
}
.pi-align-justify-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec57;');
}
.pi-align-left-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec58;');
}
.pi-align-right-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec59;');
}
.pi-and-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec5a;');
}
.pi-asterisk-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec5b;');
}
.pi-asterisk-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec5c;');
}
.pi-at-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec5d;');
}
.pi-az-sort-ascending-letters-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec5e;');
}
.pi-az-sort-descending-letters-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec5f;');
}
.pi-blockquote-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec60;');
}
.pi-bold-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec61;');
}
.pi-column-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec62;');
}
.pi-cursor-text-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec63;');
}
.pi-dividing-line-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec64;');
}
.pi-edit-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec65;');
}
.pi-edit-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec66;');
}
.pi-edit-4-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec67;');
}
.pi-edit-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec68;');
}
.pi-eraser-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec69;');
}
.pi-font-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec6a;');
}
.pi-font-size-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec6b;');
}
.pi-formula-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec6c;');
}
.pi-frame-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec6d;');
}
.pi-hashtag-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec6e;');
}
.pi-heading-1-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec6f;');
}
.pi-heading-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec70;');
}
.pi-heading-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec71;');
}
.pi-indent-decrease-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec72;');
}
.pi-indent-increase-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec73;');
}
.pi-italic-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec74;');
}
.pi-letter-spacing-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec75;');
}
.pi-line-height-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec76;');
}
.pi-list-check-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec77;');
}
.pi-list-check-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec78;');
}
.pi-list-check-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec79;');
}
.pi-list-collapse-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec7a;');
}
.pi-list-expansion-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec7b;');
}
.pi-list-ordered-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec7c;');
}
.pi-list-search-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec7d;');
}
.pi-menu-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec7e;');
}
.pi-numbers-09-sort-ascending-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec7f;');
}
.pi-numbers-09-sort-descending-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec80;');
}
.pi-numbers-90-sort-ascending-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec81;');
}
.pi-numbers-90-sort-descending-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec82;');
}
.pi-omega-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec83;');
}
.pi-paint-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec84;');
}
.pi-paragraph-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec85;');
}
.pi-pen-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec86;');
}
.pi-pencil-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec87;');
}
.pi-pencil-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec88;');
}
.pi-pencil-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec89;');
}
.pi-pencil-ruler-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec8a;');
}
.pi-quote-left-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec8b;');
}
.pi-quote-right-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec8c;');
}
.pi-signature-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec8d;');
}
.pi-sort-ascending-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec8e;');
}
.pi-sort-descending-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec8f;');
}
.pi-space-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec90;');
}
.pi-spacing-horizontal-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec91;');
}
.pi-spacing-vertical-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec92;');
}
.pi-strikethrough-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec93;');
}
.pi-subtitle-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec94;');
}
.pi-table-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec95;');
}
.pi-table-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec96;');
}
.pi-text-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec97;');
}
.pi-text-area-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec98;');
}
.pi-text-color-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec99;');
}
.pi-text-direction-left-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec9a;');
}
.pi-text-direction-right-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec9b;');
}
.pi-text-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec9c;');
}
.pi-textbox-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec9d;');
}
.pi-translate-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec9e;');
}
.pi-translate-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xec9f;');
}
.pi-underline-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeca0;');
}
.pi-za-sort-ascending-letters-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeca1;');
}
.pi-za-sort-descending-letters-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeca2;');
}
.pi-tower-crane-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeca3;');
}
.pi-tv-1-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeca4;');
}
.pi-tv-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeca5;');
}
.pi-usb-flash-disk-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeca6;');
}
.pi-usb-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeca7;');
}
.pi-vison-pro-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeca8;');
}
.pi-wash-machine-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeca9;');
}
.pi-watch-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecaa;');
}
.pi-watch-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecab;');
}
.pi-wifi-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecac;');
}
.pi-wifi-off-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecad;');
}
.pi-air-condition-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecae;');
}
.pi-air-condition-open-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecaf;');
}
.pi-airplay-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecb0;');
}
.pi-airpods-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecb1;');
}
.pi-alarm-1-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecb2;');
}
.pi-alarm-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecb3;');
}
.pi-aspect-ratio-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecb4;');
}
.pi-Barcode-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecb5;');
}
.pi-barcode-scan-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecb6;');
}
.pi-base-station-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecb7;');
}
.pi-Base-station-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecb8;');
}
.pi-battery-1-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecb9;');
}
.pi-battery-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecba;');
}
.pi-battery-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecbb;');
}
.pi-battery-4-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecbc;');
}
.pi-Battery-automotive-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecbd;');
}
.pi-battery-charging-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecbe;');
}
.pi-battery-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecbf;');
}
.pi-bluetooth-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecc0;');
}
.pi-bluetooth-off-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecc1;');
}
.pi-bulb-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecc2;');
}
.pi-bulb-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecc3;');
}
.pi-camcorder-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecc4;');
}
.pi-cardboard-vr-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecc5;');
}
.pi-ceiling-lamp-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecc6;');
}
.pi-cellphone-2-horizontal-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecc7;');
}
.pi-cellphone-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecc8;');
}
.pi-cellphone-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecc9;');
}
.pi-cellphone-vibration-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecca;');
}
.pi-chip-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeccb;');
}
.pi-computer-camera-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeccc;');
}
.pi-computer-camera-off-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeccd;');
}
.pi-computer-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecce;');
}
.pi-counter-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeccf;');
}
.pi-cube-3d-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecd0;');
}
.pi-dashboard-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecd1;');
}
.pi-dashboard-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecd2;');
}
.pi-dashboard-4-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecd3;');
}
.pi-dashboard-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecd4;');
}
.pi-desk-lamp-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecd5;');
}
.pi-desk-lamp-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecd6;');
}
.pi-device-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecd7;');
}
.pi-display-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecd8;');
}
.pi-drone-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecd9;');
}
.pi-escalator-down-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecda;');
}
.pi-escalator-up-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecdb;');
}
.pi-fax-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecdc;');
}
.pi-flash-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecdd;');
}
.pi-flashlight-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecde;');
}
.pi-fridge-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecdf;');
}
.pi-game-1-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xece0;');
}
.pi-game-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xece1;');
}
.pi-gradienter-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xece2;');
}
.pi-guitar-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xece3;');
}
.pi-high-voltage-power-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xece4;');
}
.pi-home-Wifi-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xece5;');
}
.pi-homepod-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xece6;');
}
.pi-homepod-mini-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xece7;');
}
.pi-imac-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xece8;');
}
.pi-instrument-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xece9;');
}
.pi-keyboard-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecea;');
}
.pi-keyboard-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeceb;');
}
.pi-laptop-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecec;');
}
.pi-laptop-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeced;');
}
.pi-light-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecee;');
}
.pi-microscope-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecef;');
}
.pi-midi-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecf0;');
}
.pi-monitor-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecf1;');
}
.pi-mouse-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecf2;');
}
.pi-pad-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecf3;');
}
.pi-panoramas-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecf4;');
}
.pi-plugin-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecf5;');
}
.pi-plugin-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecf6;');
}
.pi-print-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecf7;');
}
.pi-projector-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecf8;');
}
.pi-projector-screen-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecf9;');
}
.pi-qrcode-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecfa;');
}
.pi-qrcode-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecfb;');
}
.pi-radar-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecfc;');
}
.pi-radar-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecfd;');
}
.pi-radio-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecfe;');
}
.pi-remote-control-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xecff;');
}
.pi-remote-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed00;');
}
.pi-router-modem-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed01;');
}
.pi-rss-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed02;');
}
.pi-rss-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed03;');
}
.pi-sandglass-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed04;');
}
.pi-sandglass-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed05;');
}
.pi-scan-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed06;');
}
.pi-scan-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed07;');
}
.pi-server-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed08;');
}
.pi-server-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed09;');
}
.pi-shower-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed0a;');
}
.pi-signal-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed0b;');
}
.pi-solar-panel-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed0c;');
}
.pi-speaker-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed0d;');
}
.pi-stopwatch-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed0e;');
}
.pi-storage-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed0f;');
}
.pi-telescope-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed10;');
}
.pi-telescope-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed11;');
}
.pi-braces-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed12;');
}
.pi-brackets-angle-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed13;');
}
.pi-brackets-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed14;');
}
.pi-bug-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed15;');
}
.pi-code-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed16;');
}
.pi-command-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed17;');
}
.pi-cursor-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed18;');
}
.pi-cursor-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed19;');
}
.pi-cursor-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed1a;');
}
.pi-department-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed1b;');
}
.pi-directory-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed1c;');
}
.pi-git-branch-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed1d;');
}
.pi-git-commit-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed1e;');
}
.pi-git-compare-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed1f;');
}
.pi-git-merge-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed20;');
}
.pi-git-pull-request-close-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed21;');
}
.pi-git-pull-request-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed22;');
}
.pi-hotkey-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed23;');
}
.pi-inspect-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed24;');
}
.pi-parentheses-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed25;');
}
.pi-performance-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed26;');
}
.pi-sitemap-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed27;');
}
.pi-terminal-box-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed28;');
}
.pi-terminal-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed29;');
}
.pi-web-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed2a;');
}
.pi-scissors-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed2b;');
}
.pi-screenshot-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed2c;');
}
.pi-section-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed2d;');
}
.pi-shadow-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed2e;');
}
.pi-sticker-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed2f;');
}
.pi-subtract-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed30;');
}
.pi-table-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed31;');
}
.pi-unfold-horizontal-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed32;');
}
.pi-unfold-vertical-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed33;');
}
.pi-union-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed34;');
}
.pi-vector-bezier-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed35;');
}
.pi-vector-bezier-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed36;');
}
.pi-vector-bezier-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed37;');
}
.pi-vector-group-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed38;');
}
.pi-x-skew-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed39;');
}
.pi-y-skew-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed3a;');
}
.pi-align-bottom-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed3b;');
}
.pi-align-horizontal-center-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed3c;');
}
.pi-align-left-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed3d;');
}
.pi-align-right-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed3e;');
}
.pi-align-top-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed3f;');
}
.pi-align-vertical-center-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed40;');
}
.pi-anticlockwise-alt-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed41;');
}
.pi-anticlockwise-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed42;');
}
.pi-artboard-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed43;');
}
.pi-background-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed44;');
}
.pi-bling-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed45;');
}
.pi-board-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed46;');
}
.pi-border-blank-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed47;');
}
.pi-border-bottom-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed48;');
}
.pi-border-horizontal-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed49;');
}
.pi-border-inner-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed4a;');
}
.pi-border-left-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed4b;');
}
.pi-border-outer-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed4c;');
}
.pi-border-radius-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed4d;');
}
.pi-border-right-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed4e;');
}
.pi-border-top-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed4f;');
}
.pi-border-vertical-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed50;');
}
.pi-brightness-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed51;');
}
.pi-brush-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed52;');
}
.pi-clockwise-alt-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed53;');
}
.pi-clockwise-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed54;');
}
.pi-color-filter-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed55;');
}
.pi-color-picker-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed56;');
}
.pi-columns-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed57;');
}
.pi-columns-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed58;');
}
.pi-combine-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed59;');
}
.pi-components-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed5a;');
}
.pi-distribute-spacing-horizontal-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed5b;');
}
.pi-distribute-spacing-vertical-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed5c;');
}
.pi-drawing-board-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed5d;');
}
.pi-drop-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed5e;');
}
.pi-ease-in-control-point-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed5f;');
}
.pi-ease-in-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed60;');
}
.pi-ease-in-out-control-point-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed61;');
}
.pi-ease-out-control-point-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed62;');
}
.pi-ease-out-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed63;');
}
.pi-easy-in-out-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed64;');
}
.pi-exclude-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed65;');
}
.pi-exposure-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed66;');
}
.pi-flip-horizontal-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed67;');
}
.pi-flip-vertical-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed68;');
}
.pi-fold-horizontal-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed69;');
}
.pi-fold-vertical-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed6a;');
}
.pi-intersect-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed6b;');
}
.pi-knife-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed6c;');
}
.pi-layer-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed6d;');
}
.pi-layout-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed6e;');
}
.pi-layout-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed6f;');
}
.pi-layout-4-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed70;');
}
.pi-layout-5-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed71;');
}
.pi-layout-6-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed72;');
}
.pi-layout-7-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed73;');
}
.pi-layout-8-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed74;');
}
.pi-layout-9-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed75;');
}
.pi-layout-10-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed76;');
}
.pi-layout-11-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed77;');
}
.pi-layout-bottom-close-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed78;');
}
.pi-layout-bottom-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed79;');
}
.pi-layout-bottom-open-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed7a;');
}
.pi-layout-grid-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed7b;');
}
.pi-layout-left-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed7c;');
}
.pi-layout-leftbar-close-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed7d;');
}
.pi-layout-leftbar-open-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed7e;');
}
.pi-layout-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed7f;');
}
.pi-layout-right-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed80;');
}
.pi-layout-rightbar-close-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed81;');
}
.pi-layout-rightbar-open-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed82;');
}
.pi-layout-top-close-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed83;');
}
.pi-layout-top-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed84;');
}
.pi-layout-top-open-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed85;');
}
.pi-magic-1-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed86;');
}
.pi-magic-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed87;');
}
.pi-magic-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed88;');
}
.pi-magic-hat-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed89;');
}
.pi-magic-hat-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed8a;');
}
.pi-mark-pen-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed8b;');
}
.pi-markup-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed8c;');
}
.pi-mirror-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed8d;');
}
.pi-mosaic-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed8e;');
}
.pi-paint-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed8f;');
}
.pi-paint-brush-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed90;');
}
.pi-palette-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed91;');
}
.pi-palette-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed92;');
}
.pi-paster-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed93;');
}
.pi-pen-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed94;');
}
.pi-quill-pen-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed95;');
}
.pi-rotate-x-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed96;');
}
.pi-rotate-y-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed97;');
}
.pi-rows-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed98;');
}
.pi-rows-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed99;');
}
.pi-rows-4-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed9a;');
}
.pi-ruler-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed9b;');
}
.pi-scale-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed9c;');
}
.pi-scissors-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed9d;');
}
.pi-scissors-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed9e;');
}
.pi-avalanche-avax-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xed9f;');
}
.pi-binance-coin-bnb-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeda0;');
}
.pi-binance-usd-busd-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeda1;');
}
.pi-bnb-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeda2;');
}
.pi-cardano-ada-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeda3;');
}
.pi-currency-bitcoin-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeda4;');
}
.pi-currency-bitcoin-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeda5;');
}
.pi-dogecoin-doge-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeda6;');
}
.pi-ethereum-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeda7;');
}
.pi-exchange-bitcoin-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeda8;');
}
.pi-monero-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeda9;');
}
.pi-polkadot-dot-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedaa;');
}
.pi-solana-sol-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedab;');
}
.pi-tether-usdt-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedac;');
}
.pi-usd-coin-usdc-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedad;');
}
.pi-xrp-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedae;');
}
.pi-chat-1-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedaf;');
}
.pi-chat-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedb0;');
}
.pi-chat-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedb1;');
}
.pi-chat-4-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedb2;');
}
.pi-comment-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedb3;');
}
.pi-comment-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedb4;');
}
.pi-invite-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedb5;');
}
.pi-mail-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedb6;');
}
.pi-mail-open-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedb7;');
}
.pi-mail-send-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedb8;');
}
.pi-mailbox-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedb9;');
}
.pi-message-1-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedba;');
}
.pi-message-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedbb;');
}
.pi-message-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedbc;');
}
.pi-message-4-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedbd;');
}
.pi-phone-add-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedbe;');
}
.pi-phone-block-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedbf;');
}
.pi-phone-call-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedc0;');
}
.pi-phone-incoming-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedc1;');
}
.pi-phone-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedc2;');
}
.pi-phone-off-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedc3;');
}
.pi-phone-outgoing-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedc4;');
}
.pi-phone-success-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedc5;');
}
.pi-send-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedc6;');
}
.pi-send-plane-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedc7;');
}
.pi-shopping-bag-1-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedc8;');
}
.pi-shopping-bag-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedc9;');
}
.pi-shopping-bag-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedca;');
}
.pi-shopping-cart-1-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedcb;');
}
.pi-shopping-cart-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedcc;');
}
.pi-sofa-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedcd;');
}
.pi-stock-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedce;');
}
.pi-suitcase-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedcf;');
}
.pi-suitcase-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedd0;');
}
.pi-tag-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedd1;');
}
.pi-tag-chevron-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedd2;');
}
.pi-tag-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedd3;');
}
.pi-target-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedd4;');
}
.pi-ticket-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedd5;');
}
.pi-trophy-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedd6;');
}
.pi-vip-1-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedd7;');
}
.pi-vip-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedd8;');
}
.pi-vip-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedd9;');
}
.pi-vip-4-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedda;');
}
.pi-wallet-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeddb;');
}
.pi-wallet-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeddc;');
}
.pi-wallet-4-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeddd;');
}
.pi-wallet-5-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedde;');
}
.pi-wallet-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeddf;');
}
.pi-yuanbao-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xede0;');
}
.pi-ad-circle-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xede1;');
}
.pi-ad-circle-off-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xede2;');
}
.pi-ad-rectangle-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xede3;');
}
.pi-ad-rectangle-off-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xede4;');
}
.pi-anniversary-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xede5;');
}
.pi-auction-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xede6;');
}
.pi-award-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xede7;');
}
.pi-bank-card-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xede8;');
}
.pi-basket-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xede9;');
}
.pi-basket-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedea;');
}
.pi-briefcase-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedeb;');
}
.pi-calendar-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedec;');
}
.pi-calendar-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeded;');
}
.pi-calendar-add-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedee;');
}
.pi-calendar-day-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedef;');
}
.pi-calendar-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedf0;');
}
.pi-calendar-month-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedf1;');
}
.pi-calendar-Time-add-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedf2;');
}
.pi-calendar-Week-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedf3;');
}
.pi-candle-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedf4;');
}
.pi-candles-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedf5;');
}
.pi-card-pay-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedf6;');
}
.pi-card-refund-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedf7;');
}
.pi-cash-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedf8;');
}
.pi-celebrate-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedf9;');
}
.pi-chart-bar-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedfa;');
}
.pi-chart-bar-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedfb;');
}
.pi-chart-decrease-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedfc;');
}
.pi-chart-Horizontal-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedfd;');
}
.pi-chart-line-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedfe;');
}
.pi-chart-pie-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xedff;');
}
.pi-chart-pie-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee00;');
}
.pi-chart-Vertical-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee01;');
}
.pi-chines-Knot-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee02;');
}
.pi-christmas-ball-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee03;');
}
.pi-coin-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee04;');
}
.pi-coin-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee05;');
}
.pi-coin-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee06;');
}
.pi-copper-coin-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee07;');
}
.pi-copyright-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee08;');
}
.pi-coupon-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee09;');
}
.pi-currency-baht-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee0a;');
}
.pi-currency-baht-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee0b;');
}
.pi-currency-cny-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee0c;');
}
.pi-currency-cny-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee0d;');
}
.pi-currency-dollar-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee0e;');
}
.pi-currency-dollar-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee0f;');
}
.pi-currency-euro-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee10;');
}
.pi-currency-euro-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee11;');
}
.pi-currency-lira-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee12;');
}
.pi-currency-lira-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee13;');
}
.pi-currency-Nigeria-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee14;');
}
.pi-currency-Nigeria-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee15;');
}
.pi-currency-pound-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee16;');
}
.pi-currency-pound-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee17;');
}
.pi-currency-rubel-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee18;');
}
.pi-currency-rubel-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee19;');
}
.pi-currency-rupee-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee1a;');
}
.pi-currency-rupee-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee1b;');
}
.pi-currency-shekel-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee1c;');
}
.pi-currency-shekel-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee1d;');
}
.pi-currency-Won-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee1e;');
}
.pi-currency-Won-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee1f;');
}
.pi-diamond-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee20;');
}
.pi-exchange-baht-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee21;');
}
.pi-exchange-cny-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee22;');
}
.pi-exchange-dollar-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee23;');
}
.pi-exchange-euro-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee24;');
}
.pi-filter-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee25;');
}
.pi-filter-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee26;');
}
.pi-flag-1-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee27;');
}
.pi-flag-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee28;');
}
.pi-flag-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee29;');
}
.pi-flag-4-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee2a;');
}
.pi-gift-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee2b;');
}
.pi-gift-card-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee2c;');
}
.pi-gift-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee2d;');
}
.pi-ladder-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee2e;');
}
.pi-lantern-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee2f;');
}
.pi-lantern-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee30;');
}
.pi-luggage-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee31;');
}
.pi-medal-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee32;');
}
.pi-palette-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee33;');
}
.pi-pig-money-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee34;');
}
.pi-presentation-1-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee35;');
}
.pi-presentation-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee36;');
}
.pi-presentation-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee37;');
}
.pi-pumpkin-lantern-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee38;');
}
.pi-receive-money-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee39;');
}
.pi-red-packet-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee3a;');
}
.pi-red-packet-open-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee3b;');
}
.pi-refund-cny-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee3c;');
}
.pi-refund-dollar-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee3d;');
}
.pi-registered-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee3e;');
}
.pi-safe-box-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee3f;');
}
.pi-sale-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee40;');
}
.pi-schedule-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee41;');
}
.pi-seal-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee42;');
}
.pi-shopping-bag-1-line1 {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee43;');
}
.pi-shopping-bag-2-line1 {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee44;');
}
.pi-bank-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee45;');
}
.pi-bank-of-china-tower-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee46;');
}
.pi-big-ben-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee47;');
}
.pi-bridge-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee48;');
}
.pi-bridge-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee49;');
}
.pi-building-1-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee4a;');
}
.pi-building-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee4b;');
}
.pi-building-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee4c;');
}
.pi-building-4-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee4d;');
}
.pi-building-5-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee4e;');
}
.pi-building-6-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee4f;');
}
.pi-burj-al-arab-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee50;');
}
.pi-burj-khalifa-tower-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee51;');
}
.pi-campground-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee52;');
}
.pi-canton-tower-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee53;');
}
.pi-christ-the-Redeemer-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee54;');
}
.pi-church-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee55;');
}
.pi-dutch-Windmill-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee56;');
}
.pi-egyptian-pyramids-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee57;');
}
.pi-eiffel-tower-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee58;');
}
.pi-factory-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee59;');
}
.pi-factory-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee5a;');
}
.pi-ferris-Wheel-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee5b;');
}
.pi-government-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee5c;');
}
.pi-greatwall-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee5d;');
}
.pi-home-1-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee5e;');
}
.pi-home-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee5f;');
}
.pi-home-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee60;');
}
.pi-home-4-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee61;');
}
.pi-home-5-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee62;');
}
.pi-home-6-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee63;');
}
.pi-home-7-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee64;');
}
.pi-hospital-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee65;');
}
.pi-hotel-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee66;');
}
.pi-house-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee67;');
}
.pi-house-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee68;');
}
.pi-kingkey-100-tower-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee69;');
}
.pi-lighthouse-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee6a;');
}
.pi-marina-bay-sand-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee6b;');
}
.pi-maya-pyramids-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee6c;');
}
.pi-miyajima-torii-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee6d;');
}
.pi-moai-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee6e;');
}
.pi-monument-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee6f;');
}
.pi-palace-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee70;');
}
.pi-pavilion-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee71;');
}
.pi-pisa-tower-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee72;');
}
.pi-school-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee73;');
}
.pi-shop-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee74;');
}
.pi-statue-of-liberty-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee75;');
}
.pi-store-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee76;');
}
.pi-store-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee77;');
}
.pi-sydney-opera-house-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee78;');
}
.pi-taipei101-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee79;');
}
.pi-taj-mahal-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee7a;');
}
.pi-temple-of-heaven-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee7b;');
}
.pi-tent-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee7c;');
}
.pi-tower-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee7d;');
}
.pi-triumphal-arch-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee7e;');
}
.pi-tv-towe-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee7f;');
}
.pi-align-arrow-down-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee80;');
}
.pi-align-arrow-left-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee81;');
}
.pi-align-arrow-right-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee82;');
}
.pi-align-arrow-up-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee83;');
}
.pi-arrow-down-Circle-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee84;');
}
.pi-arrow-down-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee85;');
}
.pi-arrow-left-Circle-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee86;');
}
.pi-arrow-left-down-Circle-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee87;');
}
.pi-arrow-left-down-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee88;');
}
.pi-arrow-left-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee89;');
}
.pi-arrow-left-up-Circle-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee8a;');
}
.pi-arrow-left-up-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee8b;');
}
.pi-arrow-right-Circle-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee8c;');
}
.pi-arrow-right-down-Circle-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee8d;');
}
.pi-arrow-right-down-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee8e;');
}
.pi-arrow-right-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee8f;');
}
.pi-arrow-right-up-Circle-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee90;');
}
.pi-arrow-right-up-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee91;');
}
.pi-arrow-to-down-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee92;');
}
.pi-arrow-to-left-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee93;');
}
.pi-arrow-to-right-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee94;');
}
.pi-arrow-to-up-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee95;');
}
.pi-arrow-up-Circle-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee96;');
}
.pi-arrow-up-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee97;');
}
.pi-arrows-down-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee98;');
}
.pi-arrows-left-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee99;');
}
.pi-arrows-right-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee9a;');
}
.pi-arrows-up-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee9b;');
}
.pi-back-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee9c;');
}
.pi-back-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee9d;');
}
.pi-direction-arrow-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee9e;');
}
.pi-down-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xee9f;');
}
.pi-down-small-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeea0;');
}
.pi-forward-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeea1;');
}
.pi-forward-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeea2;');
}
.pi-fullscreen-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeea3;');
}
.pi-fullscreen-exit-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeea4;');
}
.pi-large-arrow-down-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeea5;');
}
.pi-large-arrow-left-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeea6;');
}
.pi-large-arrow-right-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeea7;');
}
.pi-large-arrow-up-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeea8;');
}
.pi-left-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeea9;');
}
.pi-left-small-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeeaa;');
}
.pi-move-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeeab;');
}
.pi-right-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeeac;');
}
.pi-right-small-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeead;');
}
.pi-selector-horizontal-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeeae;');
}
.pi-selector-Vertical-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeeaf;');
}
.pi-square-arrow-down-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeeb0;');
}
.pi-square-arrow-left-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeeb1;');
}
.pi-square-arrow-right-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeeb2;');
}
.pi-square-arrow-up-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeeb3;');
}
.pi-transfer-2-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeeb4;');
}
.pi-transfer-3-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeeb5;');
}
.pi-transfer-4-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeeb6;');
}
.pi-transfer-horizontal-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeeb7;');
}
.pi-transfer-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeeb8;');
}
.pi-transfer-Vertical-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeeb9;');
}
.pi-trending-down-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeeba;');
}
.pi-trending-up-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeebb;');
}
.pi-up-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeebc;');
}
.pi-up-small-line {
  *zoom: expression(this.runtimeStyle['zoom'] = '1', this.innerHTML = '&#xeebd;');
}

