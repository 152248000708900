@import "../../../../node_modules/include-media/dist/include-media";

@import "../fonts/fonts";
@import "../picha-icon-v1.0/style.scss";
@import "../picha-icon-v1.0/ie7/ie7.css";

@import "./variable/color";
@import "./variable/font";

@import "./init/mixins";
@import "./init/placeholder";
@import "./init/global";

@import "./component/grid";
@import "./component/field";
@import "./component/button";
@import "./component/table";
@import "./component/card";
@import "./component/modal";
@import "./component/alert";

@import "./layout/auth";
@import "./layout/main";
@import "./layout/page";

@import 'react-big-calendar/lib/sass/styles';
@import 'react-big-calendar/lib/addons/dragAndDrop/styles';