.kui-page-list-item{
    position: relative;
    width: 100%;
    max-width: 25em;
    padding: .8em;
    border-radius: .8em;
    background: var(--color_rgba);
    border: .02em solid transparent;
    transition: .3s ease;
    min-height: 13em;
    &.is--md{
        max-width: 30em;
    }
    .kui-table-item__avatar{
        background: var(--color_rgba);
    }
    &:hover{
        border-color: var(--primary);
        background: var(--background_tint);
    }
    display: flex;
    flex-direction: column;
    row-gap: .5em;
    justify-content: flex-end;
    .kui-tag{
        background: var(--background);
    }
    &__min{
        position: absolute;
        right: .4em;
        top: .4em;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        gap: .5em;
    }
    &__container{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 1em;
        padding-top: 1.8em;
    }
    &__illustration{
        width: 2.8em;
        height: 2.8em;
        border-radius: .8em;
        overflow: hidden;
        position: relative;
        background: var(--primary_rgba);
        color: var(--primary);
        font-size: 1.4em;
        @extend %flex-center;
        img{
            @extend %image-center;
        }
    }
    &__details{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: .2em;
        .kui-tag{
            background: var(--background);
        }
    }
    &__title{
        font-family: var(--font_title);
        font-size: 1.1em;
    }
    &__body{
        opacity: .8;
        font-size: 1em;
    }
    &__actions{
        position: relative;
        width: 100%;
        padding-top: .8em;
        border-top: .02em solid var(--color_rgba2);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: .4em;
        flex-wrap: wrap;
    }
}

.kui-page-list-user{
    position: relative;
    width: 100%;
    max-width: 17em;
    padding: .8em;
    border-radius: .8em;
    background: var(--color_rgba);
    border: .02em solid transparent;
    transition: .3s ease;
    min-height: 23em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: .8em;
    text-align: center;
    .kui-tag{
        background: var(--background);
    }

    &__avatar{
        position: relative;
        width: 5.4em;
        height: 5.4em;
        border-radius: 50%;
        // overflow: hidden;
        @extend %flex-center;
        background: var(--background);
        font-size: 1.3em;
        img{
            @extend %image-center;
            border-radius: 50%;
        }
        .notice{
            width: 1em;
            height: 1em;
            background: $success;
            border: .1em solid var(--background_tint);
            border-radius: 50%;
            position: absolute;
            bottom: 0;
            right: 0.3em;
        }
    }
    &__container{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 1em;
        div{
            display: flex;
            justify-content: center;
        }
    }
    &__name{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
        row-gap: .1em;
        strong{
            font-size: 1.5em;
            font-family: var(--font_title);
        }
        span{
            font-size: .9em;
            opacity: 8;
        }
    }
    &__min{
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        
        align-items: center;
        gap: .9em;
        .kui-table-options{
            &__list{
                
                right: 15%;
                top:0;
                transform: translateY(30%);
                &.is--open{
                    display: flex;
                    flex-direction: column;
                }
            }
        }
        .kui-table-options__item,
        .kui-table-option{
            justify-content: flex-start;
        }
    }
    &__actions{
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: .5em;
    }
    &__action{
        position: relative;
        border-radius: 50%;
        width: 3em;
        height: 3em;
        @extend %flex-center;
        background: var(--background);
        
        span{
            position: absolute;
            bottom: -50%;
            padding: .2em .5em;
            font-size: .9em;
            font-weight: 500;
            background: var(--color);
            color: var(--background);
            border-radius: .3em;
            display: none;
            white-space: nowrap;
        }
        &:hover{
            background: var(--primary_rgba);
            span{
                display: block;
            }
        }
    }
}

.kui-page-list-card{
    position: relative;
    width: 100%;
    max-width: 18em;
    min-height: 28em;
    padding: .6em;
    border-radius: .8em;
    background: var(--color_rgba);
    border: .02em solid transparent;
    &:hover{
        border-color: var(--primary);
        background: var(--background_tint);
    }
    &__image{
        position: relative;
        width: 100%;
        height: 19em;
        overflow: hidden;
        padding: .6em;
        background: var(--background_tint);
        border-radius: .6em;

        @extend %flex-center;
        i{
            font-size: 2em;
        }
        img{
            @extend %image-center;
            border-radius: .6em;
        }
    }
    &__description{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 1em;
        padding: .6em;
        h3{
            font-size: 1.2em;
            font-family: var(--font_title);
        }
    }
    &__summary{
        position: relative;
        width: 100%;
        p{
            opacity: .8;
            font-size: .9em;
        }
    }
    &__grid{
        position: relative;
        width: 100%;
        display: flex;
        row-gap: .4em;
        flex-direction: column;
    }
    &__item{
        display: flex;
        row-gap: .2em;
        align-items: center;
        opacity: .8;
        font-size: .9;
    }
    &__actions{
        position: relative;
        display: flex;
        row-gap: .8em;
        align-items: center;
        padding-top: .8em;
        border-top: .1em solid var(--color_rgba2);
    }
    .kui-tag{
        background: var(--background);
    }
    &__tag{
        display: flex;
    }
}